import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from '@swift-paypal/pp-react';
import FaqEntry from './FaqEntry';
import FaqQuestion from './FaqQuestion';
import routes from '../../../../routes/routes';
import RouteName from '../../../../routes/routeName';
import resolve from '../../../../util/resolve';
import CustomerSupportPhone from '../../customerSupportPhone/CustomerSupportPhone';
import rightArrowBlue from './right-arrow--blue.svg';
import laptopWhite from './laptop--white.svg';
import locationWhite from '../../../../shared/images/location--white.svg';
import phoneInHandWhite from './phone-in-hand--white.svg';
import usePartner from '../../../../util/usePartner';
import './faqs.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultEntries: FaqQuestion[] = require('./questions.yaml');

const contactInfo = {
  addressStreet: '2211 North First Street',
  addressCityStateZip: 'San Jose, California 95131',
  functionalCustomerSupportPhoneNumber: '1-800-347-5626',
};

type FaqsProps = {
  colorScheme?: 'gray' | 'white';
  contact?: boolean;
  displayTitle?: boolean;
  entries?: [];
  truncateCount?: number;
};

const Faqs: React.FC<FaqsProps> = ({
  colorScheme = 'gray',
  contact,
  displayTitle,
  entries = defaultEntries,
  truncateCount,
}) => {
  const partner = usePartner();
  let truncatedEntries: FaqQuestion[];

  if (truncateCount) {
    truncatedEntries = entries.slice(0, truncateCount);
  } else {
    truncatedEntries = entries;
  }

  return (
    <div className={`${!contact ? 'no-content' : ''} content-block faqs ${colorScheme}`}>
      <Container>
        {displayTitle ? (
          <Row>
            <Col className="faqs">
              <div className="ppvx_text--xl4 title">Frequently asked questions.</div>
            </Col>
          </Row>
        ) : (<></>) }
        <Row>
          <Col xl={contact ? 7 : 12} className="qa">
            <div className="accordian-override">
              <div className="ppvx_accordion">
                {truncatedEntries.map((entry: FaqQuestion, i: number) => (
                  <FaqEntry contact={contact} key={entry.question} entry={entry} index={i + 1} />
                ))}
                {(truncateCount ? (
                  <div className="see-more">
                    <Link to={resolve(routes[RouteName.FAQ].path, { partner })}>
                      See more
                      <img className="dropdown__arrow" src={rightArrowBlue} alt="right arrow" />
                    </Link>
                  </div>
                ) : <></>)}
              </div>
              <div />
            </div>
          </Col>
          {contact ? (
            <Col xl={5}>
              <div className="contact">
                <div className="contact-info">
                  <div className="img-div">
                    <img className="icon--small" src={phoneInHandWhite} alt="white mobile icon" />
                  </div>
                  <div className="contact-detail">
                    Call (toll-free):
                    {' '}
                    <CustomerSupportPhone className="contact-link" />
                    <br />
                    Monday &ndash; Friday, 9 a.m. &ndash; 8 p.m. ET
                    <br />
                    Saturday, 11 a.m. &ndash; 3 p.m. ET
                  </div>
                </div>
                <div className="contact-info">
                  <div className="img-div">
                    <img className="icon--small" src={locationWhite} alt="white location icon" />
                  </div>
                  <div className="contact-detail">
                    Swift Financial, LLC
                    <br />
                    {contactInfo.addressStreet}
                    <br />
                    {contactInfo.addressCityStateZip}
                  </div>
                </div>
                <div className="contact-info">
                  <div className="img-div">
                    <img className="icon--small" src={laptopWhite} alt="white laptop icon" />
                  </div>
                  <div className="contact-detail send-us-email">
                    <a className="contact-link" href="mailto:customerservice@loanbuilder.com">Send us an email</a>
                  </div>
                </div>
              </div>
            </Col>
          ) : (<></>)}
        </Row>
      </Container>
    </div>
  );
};

Faqs.defaultProps = {
  displayTitle: true,
  contact: true,
};

export default Faqs;
