import React, { useState } from 'react';
import {
  Text, TextInput,
} from '@swift-paypal/pp-react';
import './where-is-this.scss';

type WhereIsThisProps = {
  className?: string;
};

const WhereIsThisWrapper: React.FC<WhereIsThisProps> = (props) => {
  const [toggled, setToggled] = useState(false);
  const showExplanation = () => setToggled(!toggled);
  const { className } = props;

  const classNames = ['where-is-this-label', className || ''].join(' ');

  return (
    <div className={classNames}>
      <Text
        size="lg"
        className="underline-text"
        onClick={showExplanation}
      >
        Where is this?
      </Text>
      {
        toggled
        && (
          <div className="explanation-box">
            <Text>If you received a mailer, the Funding Code is located in the blue box.</Text>
            <Text className="funding-code-sample-label">FUNDING CODE:</Text>
            <TextInput
              disabled
              placeholder="XXXXX-XXXXXXXXX"
              type="text"
              id="funding-code-sample"
              name="funding-code-sample"
              className=""
              multiline={false}
            />
          </div>
        )
      }
    </div>
  );
};

export default WhereIsThisWrapper;
