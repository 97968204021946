import React from 'react';
import {
  Container, Row, Col, HeadingText,
} from '@swift-paypal/pp-react';
import CheckEligibilityButton from '../../common/checkEligibilityButton/CheckEligibilityButton';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import './sitelinkFaqHeroContent.scss';

const SitelinkFaqHeroContent: HeroContentComponent = () => (
  <Container as="section" className="sitelink-faq-hero">
    <Row>
      <Col sm={12}>
        <HeadingText as="h1" className="ppvx_text--xl4">
          Questions about LoanBuilder Loan?
        </HeadingText>
        <HeadingText as="h2" className="ppvx_text--xl">See below for answers to frequently asked questions.</HeadingText>
        <CheckEligibilityButton className="sitelink-faq-hero__button" />
      </Col>
    </Row>
  </Container>
);

SitelinkFaqHeroContent.backgroundClass = 'hero-ppbl-background';

export default SitelinkFaqHeroContent;
