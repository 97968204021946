/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from 'react';
import PageComponentName from '../pageComponentName';
import { AppConfigContext } from '../../util/appConfig';
import './digital.scss';
import { redirect } from '../../util/redirect';

const DigitalPage: React.FC = () => {
  const { fullstoryOrg, customerPortalUrl } = useContext(AppConfigContext);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of searchParams) {
      if (key === 'partner_camp_id') {
        localStorage.setItem(key, JSON.stringify(value));
        const now = new Date();
        const currentUTCTime = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
          now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        localStorage.setItem('partner_camp_expiry', (currentUTCTime + (2 * 24 * 60 * 60 * 1000)).toString());
      }
    }
    const leadOriginUrl = `leadOriginUrl=${window.location.href}`;
    const paypalLoanbuilderQAURl = `https://www.msmaster.qa.paypal.com/merchant-lending/loanbuilder/?${leadOriginUrl}&${searchParams.toString()}`;
    const paypalLoanbuilderProdURl = `https://www.paypal.com/merchant-lending/loanbuilder/?${leadOriginUrl}&${searchParams.toString()}`;
    if (customerPortalUrl !== 'https://my.loanbuilder.com/') {
      redirect(paypalLoanbuilderQAURl);
    } else {
      redirect(paypalLoanbuilderProdURl);
    }
  }, [customerPortalUrl]);

  return (
    <div className="digital-page" />
  );
};

DigitalPage.displayName = PageComponentName.Digital;

export default DigitalPage;
