import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageComponentName from '../../pageComponentName';
import StackLayout from '../../../layouts/stack/Stack';
import Hero from '../../../components/common/hero/Hero';
import ApplyForPPP from '../../../components/pppLoanApplication/applyForPPP/ApplyForPPP';
import ApplyOnlineForPPP from '../../../components/pppLoanApplication/applyOnlineForPPP/ApplyOnlineForPPP';
import DownloadALoanCalculator from '../../../components/pppLoanApplication/downloadALoanCalculator/DownloadALoanCalculator';
import GettingReadyToApply from '../../../components/pppLoanApplication/gettingReadyToApply/GettingReadyToApply';
import HeroContent from '../../../components/pppShared/heroContent/HeroContent';
import PPPLoanSupportHero from '../../../components/pppLoanSupport/PPPLoanSupportHero';
import LoanBasics from '../../../components/pppLoanApplication/loanBasics/LoanBasics';
import PpblHeader from '../../../components/payPalBusinessLoan/header/Header';
import StepsForForgiveness from '../../../components/pppLoanApplication/stepsForForgiveness/StepsForForgiveness';
import HowTheApplicationProcessWorks from '../../../components/pppLoanApplication/howTheApplicationProcessWorks/HowTheApplicationProcessWorks';
import './ppp-loan-support.scss';

const PPPLoanSupportPage: React.FC = () => (
  <>
    <Helmet>
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout elements={[
      <Hero
        key="Hero"
        header={<PpblHeader hideSignUp />}
        content={(
          <HeroContent
            subtitle="PayPal is accepting loan applications for the Paycheck Protection Program.*"
            content="We are providing this program to help our PayPal merchants during these challenging times."
          >
            <PPPLoanSupportHero key="HeroText" className="hero-content-text" />
          </HeroContent>
          )}
        backgroundClass={`${HeroContent.backgroundClass} ppp-loan-header`}
      />,
      <PPPLoanSupportHero key="HeroText" className="mobile-hero-text" mobile />,
      <LoanBasics key="LoanBasics" />,
      <StepsForForgiveness key="StepsForForgiveness" />,
      <ApplyForPPP key="ApplyForPPP" />,
      <DownloadALoanCalculator key="DownloadALoanCalculator" />,
      <GettingReadyToApply key="GettingReadyToApply" />,
      <HowTheApplicationProcessWorks key="HowTheApplicationProcessWorks" />,
      <ApplyOnlineForPPP key="ApplyOnlineForPPP" />,
    ]}
    />
  </>
);

PPPLoanSupportPage.displayName = PageComponentName.PPPLoanSupport;

export default PPPLoanSupportPage;
