import React, { useContext } from 'react';
import {
  Container, Row, Col, Text, Link,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../../util/appConfig';
import './speakWithOurLoanForgivenessSpecialists.scss';

const SpeakWithOurLoanForgivenessSpecialists: React.FC = () => {
  const { forgiveness3508SEnhancedEnabled } = useContext(AppConfigContext);

  return (
    <div className="content-block inverted speak-with-our-loan-forgiveness-specialist">
      <Container className="speak-with-our-loan-forgiveness-specialist__content">
        <Row align="start">
          <Col>
            <Text size="xl4" as="h2">
              Speak with our Loan Forgiveness
              <br />
              Specialists
            </Text>
            <Text size="xl" className="speak-with-our-loan-forgiveness-specialist__our-team">
              Our team is available Monday through Friday from 8 a.m. to 6 p.m. ET.
              {forgiveness3508SEnhancedEnabled && (
                <>
                  {' '}
                  Our call centers are experiencing higher than usual call volume, so we apologize
                  in advance for any longer than expected hold time as you wait to speak to a
                  specialist.
                </>
              )}
            </Text>
            <Text size="xl2">
              Call us at
              {' '}
              <strong>
                <Link
                  href="tel:18667400393"
                  className="no-line-animation speak-with-our-loan-forgiveness-specialist__phone"
                >
                  1-866-740-0393
                </Link>
              </strong>
              .
            </Text>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SpeakWithOurLoanForgivenessSpecialists;
