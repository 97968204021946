import React from 'react';
import {
  CaptionText, Col, Container, HeadingText, Row,
} from '@swift-paypal/pp-react';
import {
  CalendarIcon,
  MoneySignCircleIcon,
  RowBullet,
  ThumbPrintIcon,
  TimeArrowIcon,
} from '../../../common/rowBullet';
import './smallBusinessFunding.scss';

const SmallBusinessFunding: React.FC = () => (
  <Container fluid className="content-block small-business-funding">
    <Row align="center">
      <Col>
        <HeadingText size="lg" as="h2">
          See how PayPal Business Loan makes financing straightforward and simple.
        </HeadingText>
      </Col>
    </Row>
    <Row align="center">
      <Col md={8}>
        <div className="small-business-funding-bullets row-of-bullets">
          <RowBullet
            icon={TimeArrowIcon}
            title="Fast access to cash"
            content={(
              <>
                Check eligibility
                <sup>1</sup>
                {' '}
                in minutes. Complete the application, and if approved,
                funds are transferred as fast as the next business day.
                <sup>2</sup>
              </>
            )}
          />
          <RowBullet
            icon={MoneySignCircleIcon}
            title="Clear, competitive pricing"
            content={(
              <>
                Pay one, competitively-priced, fixed fee that you know in advance. No late fees, no
                early repayment fees, and no processing fees.
                <sup>3</sup>
              </>
            )}
          />
          <RowBullet
            icon={ThumbPrintIcon}
            title="Tailored terms"
            content={(
              <>
                Choose the loan amount and term that fit your business needs.
              </>
            )}
          />
          <RowBullet
            icon={CalendarIcon}
            title="Hassle-free repayment"
            content={(
              <>
                Predictable weekly payments will be made automatically from your business&apos;s
                bank account so there are no monthly bills to remember.
              </>
            )}
          />
        </div>
      </Col>
    </Row>
    <Row align="center">
      <Col md={7} className="small-business-funding-disclaimers">
        <CaptionText className="small-business-funding-disclaimers__sup">
          <sup>1</sup>
          This is an invitation to apply and not an offer or commitment to provide capital.
          Applicants must satisfy certain requirements to be eligible. Lender approval required -
          terms and conditions apply.
        </CaptionText>
        <CaptionText className="small-business-funding-disclaimers__sup">
          <sup>2</sup>
          The lender transfers funds as fast as the next business day for applications approved by
          5:00 p.m. Eastern Time on bank business days.
        </CaptionText>
        <CaptionText className="small-business-funding-disclaimers__sup">
          <sup>3</sup>
          In addition to the Total Loan Fee, the only other cost is a $20 Returned Item Fee that
          is only assessed if a payment is returned.
        </CaptionText>
      </Col>
    </Row>
  </Container>
);

export default SmallBusinessFunding;
