import React from 'react';
import PageComponentName from '../pageComponentName';
import LeadFormRedirect from './LeadFormRedirect';
import LeadForm from './leadForm';

const PayPalLeadsRedirect: React.FC = () => <LeadFormRedirect leadForm={LeadForm.paypal} />;

PayPalLeadsRedirect.displayName = PageComponentName.PayPalLeadsRedirect;

export default PayPalLeadsRedirect;
