import { AppConfig } from '../../../../util/appConfig';
import getStandardEntries from '../navigation/getNavigationEntries';
import Partner from '../../../../util/partner';
import { getFriendlyNumber, getFunctionalNumber } from '../../customerSupportPhone/CustomerSupportPhone';

function getNavigationEntries(appConfig: AppConfig, partner: Partner) {
  const friendlyNumber = getFriendlyNumber(appConfig, partner);
  const functionalNumber = getFunctionalNumber(friendlyNumber);
  const entries = getStandardEntries(appConfig, partner);
  const { customerPortalUrl } = appConfig;

  let linkPartner = partner;
  if (linkPartner === Partner.LoanBuilder) {
    linkPartner = Partner.App;
  }

  entries.push(
    { text: 'Log in', link: `${customerPortalUrl}login` },
    { text: `Call us at ${friendlyNumber}`, link: `tel:+${functionalNumber}` },
    { text: 'Check Eligibility', link: `https://www.loanbuilder.com/application/${linkPartner}/start` },
  );
  return entries;
}

export default getNavigationEntries;
