import React from 'react';
import {
  BodyText,
  Col,
  Container,
  Link,
  Row,
} from '@swift-paypal/pp-react';
import CheckEligibilityButton from '../../../common/checkEligibilityButton/CheckEligibilityButton';
import './mobileSubHero.scss';

const MobileSubHero: React.FC = () => (
  <div className="content-block mobile-sub-hero">
    <Container as="section">
      <Row>
        <Col>
          <BodyText className="sub-header-text">
            A fixed-term loan based on a full picture of your business that
            you automatically repay with a predictable weekly payment.
          </BodyText>
        </Col>
      </Row>
      <Row className="button-section">
        <Col>
          <BodyText>
            <CheckEligibilityButton ppblRedirect />
          </BodyText>
          <BodyText className="button-section__text">
            Don&apos;t have a PayPal Business account?
          </BodyText>
          <Link
            className="sign-up-link no-line-animation"
            target="_blank"
            href="https://www.paypal.com/bizsignup/#/checkAccount"
            rel="noopener noreferrer"
          >
            Sign up for free today.
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
);

export default MobileSubHero;
