import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container, Row, Col, Button, Text,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../util/appConfig';
import './ppp-loan-start.scss';

const PPPLoanStart: React.FC = () => {
  const { customerPortalUrl, dynamicAppBaseUrl } = useContext(AppConfigContext);
  const location = useLocation();
  const authenicatedLoginUrl = `${customerPortalUrl}login${location.search}`;
  const guestLoginUrl = `${dynamicAppBaseUrl}sba/start${location.search}`;
  return (
    <Container className="ppp-loan-start">
      <Row align="center">
        <Col>
          <Text size="xl5" as="h1">Start your PPP loan application here.</Text>
          <Button
            className="ppp-loan-start__button no-line-animation"
            as="a"
            href={authenicatedLoginUrl}
          >
            Log In to Apply
          </Button>
          <div className="ppp-loan-start__text">
            <Text>
              For borrowers who have previously received a PPP Loan
              through PayPal, a PayPal Business Loan,
              and/or a LoanBuilder Loan.
            </Text>
          </div>
          <Button
            className="ppp-loan-start__button no-line-animation"
            as="a"
            href={guestLoginUrl}
            secondary
          >
            Apply as Guest
          </Button>
          <Text>
            For all other applicants.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text size="sm" className="ppp-loan-start__footer">
            The lender for LoanBuilder Loan, PayPal Business Loan, and the Paycheck Protection
            Program Loan through PayPal is WebBank, Member, FDIC.
          </Text>
        </Col>
      </Row>
    </Container>
  );
};

export default PPPLoanStart;
