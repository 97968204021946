import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';
import ContentBlockProps from '../../common/content/contentBlockProps';
import ClockWhite from './clock_white.svg';
import PayrollWhite from './payroll_white.svg';
import OtherDocuments from './other_documents_1.svg';
import './things-you-should-know.scss';

const ThingsYouShouldKnowMobileView: React.FC<ContentBlockProps> = ({
  blue,
  inverted,
}) => {
  const classNames = [
    'content-block',
    inverted ? 'inverted' : '',
    blue ? 'blue' : '',
    'things-you-should-know-mobile-view',
  ].join(' ');

  return (
    <div className={classNames}>
      <Container>
        <Row>
          <Col>
            <Text size="xl2" as="h2">
              Things you should know if you would like to apply
              for a Paycheck Protection Program loan
            </Text>
          </Col>
        </Row>
        <div className="accordian-override">
          <div className="ppvx_accordion">
            <div className="ppvx_accordion__row">
              <button type="button" className="ppvx_accordion__header first-header" aria-expanded="false">
                <img src={PayrollWhite} alt="payroll-white-icon" />
              </button>
              <div className="ppvx_accordion__body">
                <p>
                  Funds may be used for payroll, rent, health care benefits and insurance
                  premiums, utilities, mortgage interest, or interest on other debt incurred
                  before February 15, 2020.
                </p>
              </div>
            </div>
            <div className="ppvx_accordion__row">
              <button type="button" className="ppvx_accordion__header" aria-expanded="false">
                <img src={ClockWhite} alt="clock-white-icon" />
              </button>
              <div className="ppvx_accordion__body">
                <p>
                  The PPP loan will be available through June 30, 2020, or until funds made
                  available for this program are exhausted.
                </p>
              </div>
            </div>
            <div className="ppvx_accordion__row">
              <button type="button" className="ppvx_accordion__header" aria-expanded="false">
                <img src={OtherDocuments} alt="other-documents-icon" />
              </button>
              <div className="ppvx_accordion__body">
                <p>
                  To prepare to apply, you&apos;ll want to gather payroll costs and related
                  documentation for 2019 and 2020.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default ThingsYouShouldKnowMobileView;
