import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageComponentName from '../../pageComponentName';
import StackLayout from '../../../layouts/stack/Stack';
import PPPLoanStart from '../../../components/pppLoanStart/PppLoanStart';
import PpblHeader from '../../../components/payPalBusinessLoan/header/Header';

const PPPLoanStartPage: React.FC = () => (
  <>
    <Helmet>
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout elements={[
      <PpblHeader key="PpblHeader" hideSignUp blue />,
      <PPPLoanStart key="PPPLoanStart" />,
    ]}
    />
  </>
);

PPPLoanStartPage.displayName = PageComponentName.PPPLoanStart;

export default PPPLoanStartPage;
