import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppConfigContext } from '../util/appConfig';
import { redirect } from '../util/redirect';
import PageComponentName from './pageComponentName';

const CustomerPortalRedirect: React.FC = () => {
  const { search } = useLocation();
  const { customerPortalUrl } = useContext(AppConfigContext);
  redirect(`${customerPortalUrl}login${search}`);
  return <></>;
};

CustomerPortalRedirect.displayName = PageComponentName.CustomerPortalRedirect;

export default CustomerPortalRedirect;
