enum RouteName {
  BraintreeLeadsRedirect = 'braintree-leads-redirect',
  CustomerPortalRedirect = 'customer-portal-redirect',
  Digital = 'digital',
  DirectMail = 'dm',
  FAQ = 'faq',
  Home = 'home',
  PartnerRedirect = 'partner-redirect',
  PartnerTermsAndConditions = 'partner-terms-and-conditions',
  PayPalGuestApplication = 'paypal-guest-application',
  PayPalLeadsRedirect = 'paypal-leads-redirect',
  PayPalBusinessLoan = 'paypal-business-loan',
  PayPalBusinessLoanDm = 'PayPalBusinessLoanDm',
  PayPalBusinessLoanFaq = 'paypal-business-loan-faq',
  PayPalBusinessLoanMoreInfo = 'paypal-business-loan-more-info',
  PPP2 = 'ppp-loan',
  PPPLoanApplication = 'ppp-loan-application',
  PPPLoanInfo = 'ppp-loan-info',
  PPPLoanStart = 'ppp-loan-start',
  PPPLoanSupport= 'ppp-loan-support',
  PPPLoanUpdate = 'ppp-loan-update',
  SitelinkFaq = 'faq-sl',
  SitelinkMoreInfo= 'more-info',
  TermsAndConditions = 'terms-and-conditions',
}

export default RouteName;
