import React from 'react';
import { BodyText } from '@swift-paypal/pp-react';
import './rowBullet.scss';

type RowBulletProps = {
  blue?: boolean;
  bottomLink?: JSX.Element;
  content: string | JSX.Element;
  icon: string;
  title: string;
};

const RowBullet: React.FC<RowBulletProps> = ({
  blue,
  bottomLink,
  content,
  icon,
  title,
}) => (
  <div className="row-bullet">
    <img src={icon} alt="" className={`${blue ? 'blue' : ''} row-bullet__icon`} />
    <BodyText as="p" strong className="row-bullet__title">
      {title}
    </BodyText>
    <BodyText className="row-bullet__text">
      {content}
    </BodyText>
    <div className="row-bullet__bottom-link">
      {bottomLink}
    </div>
  </div>
);

export default RowBullet;
