import React, { useState, useContext } from 'react';
import getNavigationEntries from './getNavigationEntries';
import NavEntry from '../navigation/navEntry/NavEntry';
import { AppConfigContext } from '../../../../util/appConfig';
import './mobileNavigation.scss';
import loanbuilderPayPalMobileImage from '../../../../shared/images/loanbuilder_paypal--horizontal.svg';
import usePartner from '../../../../util/usePartner';

type MobileNavigationProps = {
  hideNav?: boolean;
};

const MobileNavigation: React.FC<MobileNavigationProps> = ({ hideNav }) => {
  const [toggled, setToggled] = useState(false);
  const partner = usePartner();
  const appConfig = useContext(AppConfigContext);

  const flipToggle = () => setToggled(!toggled);
  const navEntries = getNavigationEntries(appConfig, partner);

  return (
    <div className={`mobile-navigation ${toggled ? 'x' : ''}`}>
      <img className="home-logo-mobile" src={loanbuilderPayPalMobileImage} alt="LoanBuilder A PayPal Service Logo" />
      {!hideNav
        && (
        <div className="hamburger-wrap" onClick={flipToggle}>
          <div className="hamburger" />
        </div>
        )}
      <ul role="navigation" className="nav-links">
        {(navEntries.map((entry) => <NavEntry key={entry.text} navLink={entry} />))}
      </ul>
    </div>
  );
};

export default MobileNavigation;
