import React from 'react';
import {
  CaptionText, Col, Container, HeadingText, Row,
} from '@swift-paypal/pp-react';
import CheckEligibilityButton from '../../checkEligibilityButton/CheckEligibilityButton';
import ContentBlockProps from '../contentBlockProps';
import './itTakesJustMinutesToCheckEligibility.scss';

type ItTakesJustMinutesToCheckEligibilityProps = ContentBlockProps & {
  ppblRedirect?: boolean;
  oneDisclaimer?: boolean;
};

const ItTakesJustMinutesToCheckEligibility: React.FC<ItTakesJustMinutesToCheckEligibilityProps> = ({
  blue,
  inverted,
  oneDisclaimer,
  ppblRedirect,
}) => {
  const classNames = [
    'content-block',
    inverted ? 'inverted' : '',
    blue ? 'blue' : '',
    'it-takes-just-minutes-to-check-eligibility',
  ].join(' ');

  return (
    <div className={classNames}>
      <Container>
        <Row>
          <Col>
            <HeadingText as="h2" className="ppvx_text--xl4">
              It takes just minutes to check eligibility.
              {!oneDisclaimer && (
                <sup>1</sup>
              )}
            </HeadingText>
            <CheckEligibilityButton secondary inverse={inverted} ppblRedirect={ppblRedirect} />
            <HeadingText size="sm" as="p">
              Checking eligibility will not affect your credit score.
              <sup>{oneDisclaimer ? 1 : 2}</sup>
            </HeadingText>
          </Col>
        </Row>
        <Row align="center">
          <Col md={10} className="disclaimers">
            {!oneDisclaimer && (
              <CaptionText className="disclaimers__sup">
                <sup>1</sup>
                This is an invitation to apply and not an offer or commitment to provide capital.
                Applicants must satisfy certain requirements to be eligible. Lender approval
                required - terms and conditions apply.
              </CaptionText>
            )}
            <CaptionText className="disclaimers__sup">
              <sup>{oneDisclaimer ? 1 : 2}</sup>
              Completing the questionnaire will assess your business&apos;s eligibility,
              allow you to review estimated offers if eligible, and will not impact your
              credit score. Should you decide to complete the loan application process,
              credit checks and other public records checks will be performed which may
              impact your credit score.
            </CaptionText>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ItTakesJustMinutesToCheckEligibility;
