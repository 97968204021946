import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import './faq-hero-content.scss';

const FaqHeroContent: HeroContentComponent = () => (
  <Container as="section" className="hero-faq-content">
    <Row>
      <Col>
        <Text size="xl4" as="h1">Support</Text>
        <Text size="xl2">Got questions? We have answers.</Text>
      </Col>
    </Row>
  </Container>
);

FaqHeroContent.backgroundClass = 'hero-faq-background';

export default FaqHeroContent;
