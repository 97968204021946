import React from 'react';
import {
  Accordion,
  AccordionRow,
  BodyText,
  Button,
  Col,
  Container,
  HeadingText,
  Link,
  Row,
} from '@swift-paypal/pp-react';
import './frequentlyAskedQuestions.scss';

type PpblFrequentlyAskedQuestionsProps = {
  directMail?: boolean;
};

const PpblFrequentlyAskedQuestions: React.FC<PpblFrequentlyAskedQuestionsProps> = ({
  directMail = false,
}) => (
  <>
    <div className="content-block ppbl-frequently-asked-questions">
      <Container>
        <Row>
          <Col>
            <HeadingText as="h2" className="ppvx_text--xl4">
              Frequently Asked Questions
            </HeadingText>
            <Accordion name="ppbl-faqs" className="accordion">
              <AccordionRow heading="1. How does a PayPal Business Loan work?" accordionName="ppbl-faqs" rowNumber="1">
                <BodyText>
                  You must complete a 5-10 minute online questionnaire to determine your
                  business&apos;s eligibility. Once the questionnaire is complete, estimated loan
                  terms can be customized by adjusting the loan amount and duration to compare
                  the costs of financing.
                </BodyText>
                <br />
                <BodyText>
                  Once you select your desired terms and completed the application, if approved,
                  you will be prompted to electronically sign a contract that will be emailed to you
                  and includes bank instructions so that payments can be debited weekly from your
                  business checking account.
                </BodyText>
              </AccordionRow>
              <AccordionRow heading="2. If approved, how can I get the funds?" accordionName="ppbl-faqs" rowNumber="2">
                <BodyText>
                  If approved, the loan is transferred by the lender directly into your PayPal
                  Business account. Funding can be as fast as the next business day if documents
                  are in and the loan is approved by 5:00 p.m. Eastern Time on bank business days.
                </BodyText>
              </AccordionRow>
              <AccordionRow heading="3. Is my business eligible for the PayPal Business Loan?" accordionName="ppbl-faqs" rowNumber="3">
                <BodyText>
                  The online questionnaire can help you determine your business&apos;s eligibility
                  for a PayPal Business Loan. It takes just a few minutes to complete.
                </BodyText>
              </AccordionRow>
              <AccordionRow heading="4. Will exploring a PayPal Business Loan impact my credit score?" accordionName="ppbl-faqs" rowNumber="4">
                <BodyText>
                  Completing the questionnaire will assess your business&apos;s eligibility, allow
                  you to review estimated offers if eligible, and will not impact your credit score.
                  Should you decide to complete the loan application process, credit checks and
                  other public records checks will be performed which may impact your credit score.
                </BodyText>
              </AccordionRow>
              <>
                {directMail && (
                <>
                  <AccordionRow heading="5. How does the lender determine my business's eligibility and loan offer?" accordionName="ppbl-faqs" rowNumber="5">
                    <BodyText>
                      Your business&apos;s eligibility and loan offer are based on an overall
                      assessment of your business&apos;s health. The lender evaluates
                      the credit history of the business owner and the business to determine
                      approvability, the loan amount, and pricing.
                    </BodyText>
                  </AccordionRow>
                  <AccordionRow heading="6. Can I pay off a PayPal Business Loan early?" accordionName="ppbl-faqs" rowNumber="6">
                    <BodyText>
                      The loan can be paid off at any time, but the full fee based on the
                      original term of the loan is applied and is due at the time of payoff.
                    </BodyText>
                  </AccordionRow>
                  <AccordionRow heading="7. How do I repay a PayPal Business Loan?" accordionName="ppbl-faqs" rowNumber="7">
                    <BodyText>
                      Your PayPal Business Loan has weekly payments that are withdrawn
                      from your business&apos;s bank account automatically. You provide account
                      information and select your preferred payment day when you sign the loan
                      contract. Automatic repayment will occur weekly and you will not be able
                      to adjust how often you pay. You will be able to choose the day of the week
                      payment is made.
                    </BodyText>
                  </AccordionRow>
                  <AccordionRow heading="8. Do I need a PayPal Business account to get a PayPal Business Loan?" accordionName="ppbl-faqs" rowNumber="8">
                    <BodyText>
                      Yes. PayPal Business Loan is available exclusively to PayPal Business
                      customers. If you&apos;re not a current PayPal Business customer,
                      opening a PayPal Business account is easy – you can sign up for free
                      {' '}
                      <Link
                        href="https://www.paypal.com/us/bizsignup/#/checkAccount"
                        className="sign-up-link no-line-animation"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </Link>
                      .
                    </BodyText>
                  </AccordionRow>
                  <AccordionRow heading="9. Who is WebBank, Swift Financial, and LoanBuilder?" accordionName="ppbl-faqs" rowNumber="9">
                    <BodyText>
                      The lender for PayPal Business Loan is WebBank, Member FDIC. The PayPal
                      Business Loan is serviced by Swift Financial, LLC, a subsidiary of
                      PayPal, Inc. LoanBuilder, A PayPal Service, is a product name that
                      powers the PayPal Business Loan application.
                    </BodyText>
                  </AccordionRow>
                  <AccordionRow heading="10. Does a PayPal Business Loan require a personal guarantee?" accordionName="ppbl-faqs" rowNumber="10">
                    <BodyText>
                      Yes, a personal guarantee is required, but you will find that many business
                      loans also require a personal guarantee.
                    </BodyText>
                  </AccordionRow>
                </>
                )}
              </>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
    {!directMail && (
      <div className="content-block ppbl-frequently-asked-questions-button">
        <Container>
          <Row>
            <Col>
              <Button size="sm" as="a" href="https://www.paypal.com/us/webapps/mpp/paypal-business-loan-faq" secondary>
                See all FAQs
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    )}
  </>
);

export default PpblFrequentlyAskedQuestions;
