import React from 'react';
import {
  Container, Row, Col, Text, Divider, Button,
} from '@swift-paypal/pp-react';
import './steps-for-forgiveness.scss';

const StepsForForgiveness: React.FC = () => (
  <Container>
    <Row align="center" className="steps-for-forgiveness-title" noGutters>
      <Col lg={10} md={12}>
        <Text size="xl4" className="steps-for-forgiveness-header">
          Three steps for PPP loan borrowers to achieve loan forgiveness*
        </Text>
        <Text size="md">
          Loan forgiveness means that up to the full amount of your loan may not have to be
          repaid. You will need to submit an application and the supporting documentation as part
          of your forgiveness request.
        </Text>
      </Col>
    </Row>
    <Divider secondary />
    <Row noGutters className="steps-for-forgiveness-content">
      <Col lg={4} md={10} sm={12}>
        <div className="step">
          <Text size="lg" className="step-image">
            <div className="number">1</div>
          </Text>
          <Text className="step-text" size="md">
            Use the loan for the intended, eligible purposes during the 24 weeks (or, as applicable,
            8 weeks) after the loan is funded.
          </Text>
        </div>
      </Col>
      <Col lg={4} md={10} sm={12}>
        <div className="step">
          <Text size="lg" className="step-image">
            <div className="number">2</div>
          </Text>
          <Text className="step-text" size="md">
            Ensure that at least 60% of the loan use is for eligible payroll costs and the remainder
            for other eligible expenses during the 24-week (or, as applicable, 8 week) time frame.
          </Text>
        </div>
      </Col>
      <Col lg={4} md={10} sm={12}>
        <div className="step">
          <Text size="lg" className="step-image">
            <div className="number">3</div>
          </Text>
          <Text className="step-text" size="md">
            If you have employees, full-time employee headcount must be maintained or restored, and
            compensation levels can&apos;t be cut by more than 25% if they make less than $100,000
            to help maximize forgiveness.**
          </Text>
        </div>
      </Col>
    </Row>
    <Row className="steps-for-forgiveness-disclaimer">
      <Col>
        <Button
          as="a"
          className="no-line-animation learn-more"
          href="/ppp-loan-info"
          target="_blank"
        >
          Learn More
        </Button>
        <Text size="md">
          *Loan forgiveness is subject to satisfying SBA loan forgiveness eligibility criteria and
          documentation requirements.
        </Text>
        <Text size="md">
          **The employee headcount retention requirement is subject to certain exemptions and safe
          harbors, for example, if you restore employee headcount to certain historical levels; make
          good faith efforts to rehire employees, but they refuse; or were unable to operate at
          normal business levels as a result of governmental health or safety directives related
          to COVID-19.
        </Text>
        <Text size="md">
          The lender for the Paycheck Protection Program loan through PayPal is WebBank, Member
          FDIC.
        </Text>
      </Col>
    </Row>
  </Container>
);

export default StepsForForgiveness;
