import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageComponentName from '../../pageComponentName';
import StackLayout from '../../../layouts/stack/Stack';
import Hero from '../../../components/common/hero/Hero';
import HeroContent from '../../../components/pppLoanUpdate/heroContent/HeroContent';
import LoanBasics from '../../../components/pppLoanApplication/loanBasics/LoanBasics';
import PpblHeader from '../../../components/payPalBusinessLoan/header/Header';
import StepsForForgiveness from '../../../components/pppLoanApplication/stepsForForgiveness/StepsForForgiveness';
import ThingsYouShouldKnow from '../../../components/pppLoanUpdate/thingsYouShouldKnow/thingsYouShouldKnow';
import ThingsYouShouldKnowMobileView from '../../../components/pppLoanUpdate/thingsYouShouldKnow/thingsYouShouldKnowMobileView';
import './ppp-loan-update.scss';

const PPPLoanUpdatePage: React.FC = () => (
  <>
    <Helmet>
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout elements={[
      <Hero
        key="Hero"
        header={<PpblHeader hideSignUp />}
        content={(<HeroContent />)}
        backgroundClass={`${HeroContent.backgroundClass} ppp-loan-header`}
      />,
      <LoanBasics key="LoanBasics" />,
      <StepsForForgiveness key="StepsForForgiveness" />,
      <ThingsYouShouldKnow key="ThingsYouShouldKnow" blue inverted />,
      <ThingsYouShouldKnowMobileView key="ThingsYouShouldKnowMobileView" blue inverted />,
    ]}
    />
  </>
);

PPPLoanUpdatePage.displayName = PageComponentName.PPPLoanUpdate;

export default PPPLoanUpdatePage;
