import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageComponentName from '../../pageComponentName';
import Header from '../../../components/common/header/Header';
import Hero from '../../../components/common/hero/Hero';
import StackLayout from '../../../layouts/stack/Stack';
import { SitelinkMoreInfoHeroContent } from '../../../components/sitelinkMoreInfo';
import SmallBusinessFunding from '../../../components/sitelinkMoreInfo/smallBusinessFunding/SmallBusinessFunding';
import { SitelinkCheckEligibility } from '../../../components/sitelinkFaq';
import Footer from '../../../components/common/footer/Footer';
import './sitelinkMoreInfo.scss';

const SitelinkMoreInfoPage: React.FC = () => (
  <div className="site-link-more-info-page">
    <Helmet meta={[
      <meta name="robots" content="noindex, nofollow" />,
    ]}
    >
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout
      elements={[
        <Hero
          key="Hero"
          header={<Header hideNav />}
          content={(
            <SitelinkMoreInfoHeroContent key="MoreInfoHeroContent" />
          )}
          backgroundClass={SitelinkMoreInfoHeroContent.backgroundClass}
        />,
        <SmallBusinessFunding key="SmallBusinessFunding" />,
        <SitelinkCheckEligibility key="CheckEligibility" />,
        <Footer key="Footer" />,
      ]}
    />
  </div>
);

SitelinkMoreInfoPage.displayName = PageComponentName.SitelinkMoreInfo;

export default SitelinkMoreInfoPage;
