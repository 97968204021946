import React from 'react';
import {
  Container, Row, Col, Text, Link,
} from '@swift-paypal/pp-react';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import CheckEligibilityButton from '../../common/checkEligibilityButton/CheckEligibilityButton';
import './ppbl-hero-content.scss';

const PpblHeroContent: HeroContentComponent = () => (
  <Container as="section" className="hero-ppbl-content">
    <Row>
      <Col>
        <Text className="header-text" size="xl4" as="h1">
          PayPal Business Loan
        </Text>
        <Text className="sub-header-text" size="xl" as="h2" regular>
          A fixed-term loan based on a full picture of your business that
          <br />
          you automatically repay with a predictable weekly payment.
        </Text>
      </Col>
    </Row>
    <Row className="button-section">
      <Col sm={4}>
        <Text>
          <CheckEligibilityButton ppblRedirect />
        </Text>
        <Text>
          Don&apos;t have a PayPal Business account?
          <br />
          <Link
            className="sign-up-link"
            href="https://www.paypal.com/bizsignup/#/checkAccount"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up for free today.
          </Link>
        </Text>
      </Col>
    </Row>
  </Container>
);

PpblHeroContent.backgroundClass = 'hero-ppbl-background';

export default PpblHeroContent;
