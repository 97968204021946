import { reverse } from 'named-urls';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const buildQueryString = require('build-query-string');

const matchUrlParams = /:([A-za-z]+)/g;

type ResolveParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export default function resolve(pathname: string, params: ResolveParams) {
  const urlParams: ResolveParams = {};
  const queryStringParams: ResolveParams = {};
  const matches = [...pathname.matchAll(matchUrlParams)];

  if (!matches) {
    const queryString = buildQueryString(params);
    if (queryString === '') {
      return pathname;
    }

    return `${pathname}?${queryString}`;
  }

  matches.forEach((match) => {
    const name = match[1];
    if (Object.prototype.hasOwnProperty.call(params, name)) {
      urlParams[name] = params[name];
    }
  });

  Object.keys(params).forEach((key) => {
    if (!Object.prototype.hasOwnProperty.call(urlParams, key)) {
      queryStringParams[key] = params[key];
    }
  });

  const url = reverse(pathname, urlParams);
  const queryString = buildQueryString(queryStringParams);
  if (queryString === '') {
    return url;
  }
  return `${url}?${queryString}`;
}
