import React from 'react';
import { Route } from 'react-router-dom';

import routes from './routes/routes';
import { getInnerDisplayName } from './util/getDisplayName';

import BraintreeLeadsRedirectPage from './pages/leadForms/BraintreeLeadsRedirect';
import CustomerPortalRedirectPage from './pages/CustomerPortalRedirect';
import DigitalPage from './pages/digital/Digital';
import DirectMailPage from './pages/DirectMail';
import HomePage from './pages/home/Home';
import FaqsPage from './pages/Faqs';
import PartnerRedirectPage from './pages/PartnerRedirect';
import PayPalApplicationRedirectPage from './pages/paypalApplicationRedirect';
import PayPalLeadsRedirectPage from './pages/leadForms/PayPalLeadsRedirect';
import PayPalBusinessLoanPage from './pages/payPalBusinessLoan/PayPalBusinessLoan';
import PayPalBusinessLoanDmPage from './pages/payPalBusinessLoan/PayPalBusinessLoanDm';
import PayPalBusinessLoanFaqPage from './pages/payPalBusinessLoanFaq/PayPalBusinessLoanFaq';
import PayPalBusinessLoanMoreInfoPage from './pages/payPalBusinessLoanMoreInfo/PayPalBusinessLoanMoreInfo';
import PPP2Page from './pages/ppp-pages/ppp2/PPP2';
import PPPLoanApplicationPage from './pages/ppp-pages/ppp-loan-application/PPPLoanApplication';
import PPPLoanInfoPage from './pages/ppp-pages/ppp-loan-info/PPPLoanInfo';
import PPPLoanStartPage from './pages/ppp-pages/ppp-loan-start/PPPLoanStart';
import PPPLoanSupportPage from './pages/ppp-pages/ppp-loan-support/PPPLoanSupport';
import PPPLoanUpdatePage from './pages/ppp-pages/pppLoanUpdate/PPPLoanUpdate';
import PPPLoanRedirectPage from './pages/pppLoanRedirect';
import SitelinkFaqPage from './pages/sitelink/Faq/SitelinkFaq';
import SitelinkMoreInfo from './pages/sitelink/MoreInfo/SitelinkMoreInfo';
import TermsAndConditionsPage from './pages/termsAndConditions/TermsAndConditions';
import withTracker from './routes/withTracker';
import withValidPartnerRedirect from './routes/withValidPartnerRedirect';

const components: React.ComponentType[] = [
  BraintreeLeadsRedirectPage,
  CustomerPortalRedirectPage,
  DigitalPage,
  DirectMailPage,
  FaqsPage,
  HomePage,
  PartnerRedirectPage,
  PayPalApplicationRedirectPage,
  PayPalLeadsRedirectPage,
  PayPalBusinessLoanPage,
  PayPalBusinessLoanDmPage,
  PayPalBusinessLoanFaqPage,
  PayPalBusinessLoanMoreInfoPage,
  PPP2Page,
  PPPLoanApplicationPage,
  PPPLoanInfoPage,
  PPPLoanStartPage,
  PPPLoanSupportPage,
  PPPLoanUpdatePage,
  PPPLoanRedirectPage,
  SitelinkFaqPage,
  SitelinkMoreInfo,
  TermsAndConditionsPage,
];

interface ComponentMap {
  [name: string]: React.ComponentType;
}

const componentsByName = components.reduce((map, component) => {
  const name = getInnerDisplayName(component);
  const newMap = { ...map };
  newMap[name] = component;
  return newMap;
}, {} as ComponentMap);

export default function getRoutes() {
  return Object.values(routes).map((route) => {
    const component = componentsByName[route.componentName];
    if (!component) {
      throw new Error(`Could not find component ${route.componentName} when mapping ${route.path}.`);
    }
    const WrappedComponent = route.path.includes(':partner')
      ? withTracker(withValidPartnerRedirect(component, route.path))
      : withTracker(component);

    return (
      <Route
        key={route.path}
        path={route.path}
        element={<WrappedComponent />}
      />
    );
  });
}
