import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';
import ContentBlockProps from '../../common/content/contentBlockProps';
import PPPRowBullet from '../../pppShared/pppRowBullet/PPPRowBullet';
import ClockWhite from './clock_white.svg';
import PayrollWhite from './payroll_white.svg';
import OtherDocuments from './other_documents_1.svg';
import './things-you-should-know.scss';

const ThingsYouShouldKnow: React.FC<ContentBlockProps> = ({
  blue,
  inverted,
}) => {
  const classNames = [
    'content-block',
    inverted ? 'inverted' : '',
    blue ? 'blue' : '',
    'things-you-should-know',
  ].join(' ');

  return (
    <div className={classNames}>
      <Container>
        <Row>
          <Col>
            <Text size="xl4" as="h2">
              Things you should know if you would like to apply
              for a Paycheck Protection Program loan
            </Text>
          </Col>
        </Row>
        <Row align="center">
          <Col lg={4} sm={12}>
            <PPPRowBullet
              icon={PayrollWhite}
              content={(
                <Text size="md">
                  Funds may be used for payroll, rent, health care benefits and insurance
                  premiums, utilities, mortgage interest, or interest on other debt incurred
                  before February 15, 2020.
                </Text>
              )}
              classNames="left-align"
              blue
            />
          </Col>
          <Col lg={4} sm={12}>
            <PPPRowBullet
              icon={ClockWhite}
              content={(
                <Text size="md">
                  The PPP loan will be available through June 30, 2020, or until funds made
                  available for this program are exhausted.
                </Text>
              )}
              classNames="left-align"
              blue
            />
          </Col>
          <Col lg={4} sm={12}>
            <PPPRowBullet
              icon={OtherDocuments}
              content={(
                <Text size="md">
                  To prepare to apply, you&apos;ll want to gather payroll costs and related
                  documentation for 2019 and 2020.
                </Text>
              )}
              classNames="left-align"
              blue
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ThingsYouShouldKnow;
