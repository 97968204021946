import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@swift-paypal/pp-react';
import generateApplicationStartUrlByPartner from '../../../util/generateApplicationStartUrlByPartner';
import { AppConfigContext } from '../../../util/appConfig';
import StorageKey from '../../../util/storageKey';
import './checkEligibilityButton.scss';
import usePartner from '../../../util/usePartner';
import getCampaignParameter from '../../../util/getCampaignParameter';
import storeUTMCampaign from '../../../util/storeUTMCampaign';

const ppblRedirectUrl = 'https://www.paypal.com/us/webapps/mpp/paypal-business-loan/check-eligibility';

type CheckEligibilityButtonProps = {
  className?: string;
  inverse?: boolean;
  secondary?: boolean;
  ppblRedirect?: boolean;
};

const CheckEligibilityButton: React.FC<CheckEligibilityButtonProps> = ({
  className,
  inverse,
  secondary,
  ppblRedirect,
}) => {
  const location = useLocation();
  const campaignId = getCampaignParameter(location);
  const partner = usePartner();
  const { dynamicAppBaseUrl } = useContext(AppConfigContext);
  const leadOriginUrl = sessionStorage.getItem(StorageKey.LeadOriginUrl) as string;

  const classNames = ['check-eligibility-button no-line-animation', className || ''].join(' ');

  let url: string;
  if (ppblRedirect) {
    url = ppblRedirectUrl;
    storeUTMCampaign(campaignId);
  } else {
    url = generateApplicationStartUrlByPartner(dynamicAppBaseUrl, partner, leadOriginUrl, {
      search: location.search,
    });
  }

  return (
    <Button as="a" href={url} className={classNames} inverse={inverse} secondary={secondary}>
      Check Eligibility
    </Button>
  );
};

export default CheckEligibilityButton;
