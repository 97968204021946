import React from 'react';
import {
  Col, Container, Row, Text,
} from '@swift-paypal/pp-react';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import AlertIcon from './alert_01.svg';
import './hero-content.scss';

const HeroContent: HeroContentComponent = () => (
  <Container as="section" className="hero-content">
    <Row align="center">
      <Col lg={7} xs={11}>
        <img src={AlertIcon} className="icon" alt="alert-icon" />
        <Text size="xl4" as="h1">
          Paycheck Protection Program through PayPal Update:
        </Text>
        <Text size="xl">
          Because the funding allocated by the US Government for the Paycheck
          Protection Program has been fully distributed, we are currently not
          accepting new applications.
        </Text>
        <Text size="xl">
          If more funding is designated for this program, we plan to make the
          application available again. If you have already applied, we will be
          contacting you through email.
        </Text>
        <Text size="sm" className="disclosure">
          The lender for the Paycheck Protection Program loan
          through PayPal is WebBank, Member FDIC.
        </Text>
      </Col>
    </Row>
  </Container>
);

HeroContent.backgroundClass = 'hero-background';

export default HeroContent;
