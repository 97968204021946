import urlJoin from 'proper-url-join';
import DynamicProduct from './dynamicProduct';

export type QueryParams = {
  sourceCode: string;
  identity: string;
  directMail: number;
  leadOriginUrl: string;
};

export type GenerateStartUrlOptions = {
  query?: Partial<QueryParams>;
  search?: string;
};

export default function generateApplicationStartUrl(
  dynamicAppBaseUrl: string,
  product: DynamicProduct,
  options: GenerateStartUrlOptions = { query: {} },
) {
  const search = options.search || '';
  const { query } = options;

  const params = Object.entries(query || {}).reduce((accumulator, entry) => {
    const [key, value] = entry;
    if (value) {
      accumulator[key] = value;
    }
    return accumulator;
  }, {} as {[key: string]: string|number|ReadonlyArray<string|number>});

  return urlJoin(dynamicAppBaseUrl, product, 'start', search, {
    query: {
      ...params,
    },
  });
}
