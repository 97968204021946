import React from 'react';
import {
  BodyText, Col, Container, HeadingText, Row,
} from '@swift-paypal/pp-react';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import CheckEligibilityButton from '../../common/checkEligibilityButton/CheckEligibilityButton';
import './ppbl-more-info-hero-content.scss';

const PpblMoreInfoHeroContent: HeroContentComponent = () => (
  <Container as="section" className="hero-ppbl-more-info-content">
    <Row>
      <Col>
        <HeadingText className="header-text" size="hero" as="h1">
          PayPal Business Loan
        </HeadingText>
        <HeadingText className="sub-header-text" size="md" as="h2">
          A fixed-term loan based on a full picture of your business that
          <br />
          you automatically repay with a predictable weekly payment.
        </HeadingText>
      </Col>
    </Row>
    <Row className="button-section">
      <Col sm={4}>
        <BodyText>
          <CheckEligibilityButton ppblRedirect />
        </BodyText>
      </Col>
    </Row>
  </Container>
);

PpblMoreInfoHeroContent.backgroundClass = 'hero-ppbl-more-info-background';

export default PpblMoreInfoHeroContent;
