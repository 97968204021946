/* eslint-disable import/prefer-default-export */
import React from 'react';

type CustomerSupportPhoneConfig = {
  loanbuilder: string;
  paypal: string;
};

export interface AppConfig {
  apiUrl: string;
  covidReliefBannerPppV3: boolean;
  covidReliefBillUpdateEnabled: boolean;
  customerPortalUrl: string;
  customerSupportPhone: CustomerSupportPhoneConfig;
  dynamicAppBaseUrl: string;
  forgiveness3508SEnhancedEnabled: boolean;
  fullstoryOrg: string;
  googleTagManagerId: string;
  heapAppId: string;
  ppp21LoFoEnabled: boolean;
  privacyPolicyUrl: string;
  sba2ApplicationAvailable: boolean;
  sba2ApplicationClosed: boolean;
  sba2OutOfFunding: boolean;
  sba2ProgramHasEnded: boolean;
  swiftFinancialUrl: string;
  termsOfUseUrl: string;
  version: string;
}

export const AppConfigContext = React.createContext<AppConfig>({
  apiUrl: 'https://dynapi.dev.loanbuilder.com/v1',
  covidReliefBannerPppV3: true,
  covidReliefBillUpdateEnabled: false,
  customerPortalUrl: 'https://my.dev.loanbuider.com/',
  customerSupportPhone: {
    loanbuilder: '1-800-347-5626',
    paypal: '1-800-941-5614',
  },
  dynamicAppBaseUrl: 'https://dev.loanbuilder.com/application/',
  forgiveness3508SEnhancedEnabled: false,
  fullstoryOrg: 'G9X5',
  googleTagManagerId: 'GTM-NXQ3QH',
  heapAppId: '1616212293',
  ppp21LoFoEnabled: false,
  privacyPolicyUrl: 'https://my.dev.loanbuilder.com/privacy-policy',
  sba2ApplicationAvailable: false,
  sba2ApplicationClosed: false,
  sba2OutOfFunding: false,
  sba2ProgramHasEnded: false,
  swiftFinancialUrl: 'https://dev.swiftfinancial.com/',
  termsOfUseUrl: 'https://my.dev.loanbuilder.com/terms-of-use',
  version: 'v0.0.0-0',
});
