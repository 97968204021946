/* eslint-disable */
import React from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AppConfig, AppConfigContext } from "./util/appConfig";
import getRoutes from "./routesFactory";
import TrailingSlashRedirect from "./routes/TrailingSlashRedirect";
import routes from "./routes/routes";
import RouteName from "./routes/routeName";
import resolve from "./util/resolve";
import ScrollToTop from "./util/scrollToTop";
import Partner from "./util/partner";
import { currentLocation } from "./util/redirect";
import StorageKey from "./util/storageKey";
import PageRedirect from "./components/common/PageRedirect";
import initializeGoogleTagManager from "./util/initGoogleTagManager";
import NewLandingRedirectPage from "./NewLandingRedirect";

type AppProps = {
  config: AppConfig;
};

const helmetContext = {};

const App: React.FC<AppProps> = ({ config }) => {
  const tagManagerArgs = {
    gtmId: config.googleTagManagerId,
  };

  if (!sessionStorage.getItem(StorageKey.LeadOriginUrl)) {
    sessionStorage.setItem(StorageKey.LeadOriginUrl, currentLocation);
  }

  initializeGoogleTagManager(tagManagerArgs)

  

  return (
    <HelmetProvider context={helmetContext}>
      <AppConfigContext.Provider value={config}>
        <Router>
          <ScrollToTop />
          <NewLandingRedirectPage/>
        </Router>
      </AppConfigContext.Provider>
    </HelmetProvider>
  );
};

export default App;
