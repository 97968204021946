import React from 'react';
import {
  Text, Container, Row, Col,
} from '@swift-paypal/pp-react';
import ApplyNowButton from './applyNowButton/ApplyNowButton';

export type HeroText2Props = {
  className?: string;
  mobile?: boolean;
};

const PPPLoanApplicationHero: React.FC<HeroText2Props> = (props) => {
  const { className, mobile } = props;
  return (
    <Container fluid>
      <Row>
        <Col>
          <Text className={className} size="xl">
            The SBA has confirmed that June 30, 2020 is the last date on which a PPP loan
            application can be approved. PayPal submits new applications to the SBA for approval
            after your loan application and documentation have been reviewed to confirm eligibility
            requirements, and you have signed and returned all loan documents.
          </Text>
          <div className="hero-apply-now">
            <ApplyNowButton mobile={mobile} />
          </div>
          <Text size="md" className={className}>
            *The lender for the Paycheck Protection
            <br />
            Program loan through PayPal is WebBank,
            <br />
            Member FDIC.
          </Text>
        </Col>
      </Row>
    </Container>
  );
};

export default PPPLoanApplicationHero;
