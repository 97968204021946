import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';
import ApplyNowButton from '../applyNowButton/ApplyNowButton';
import './apply-online-for-ppp.scss';

const ApplyOnlineForPPP: React.FC = () => (
  <div className="apply-online-for-ppp">
    <Container>
      <Row align="center" noGutters>
        <Col lg={10} sm={12}>
          <Text size="xl4" className="title">
            Apply online for a Paycheck Protection Program loan through PayPal.*
          </Text>
          <ApplyNowButton />
          <ApplyNowButton mobile />
          <Text size="md" className="disclaimer">
            *The lender for the Paycheck Protection Program loan through PayPal is WebBank, Member
            FDIC. Terms and conditions apply. Loan subject to Lender Approval and SBA eligibility
            and approval criteria under the Paycheck Protection Program.
          </Text>
        </Col>
      </Row>
    </Container>
  </div>
);

export default ApplyOnlineForPPP;
