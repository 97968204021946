import React from 'react';
import { Helmet } from 'react-helmet-async';
import StackLayout from '../../layouts/stack/Stack';
import CallUsToday from '../../components/common/content/callUsToday/CallUsToday';
import Faqs from '../../components/common/content/faqs/Faqs';
import Footer from '../../components/common/footer/Footer';
import Hero from '../../components/common/hero/Hero';
import HomeHeroContent from '../../components/home/heroContent/HomeHeroContent';
import ItTakesJustMinutesToCheckEligibility from '../../components/common/content/itTakesJustMinutesToCheckEligibility/ItTakesJustMinutesToCheckEligibility';
import Mailer from '../../components/common/mailer/Mailer';
import PageComponentName from '../pageComponentName';
import SpeakWithOurBusinessFundingExpertsToday from '../../components/common/content/speakWithOurBusinessFundingExpertsToday/SpeakWithOurBusinessFundingExpertsToday';
import WhyALoanBuilderLoan from '../../components/common/content/whyALoanBuilderLoan/WhyALoanBuilderLoan';
import './home.scss';

const HomePage: React.FC = () => (
  <div className="home-page">
    <Helmet>
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout
      elements={[
        <Hero
          key="Hero"
          content={<HomeHeroContent />}
          tickler={<Mailer />}
          backgroundClass={HomeHeroContent.backgroundClass}
        />,
        <WhyALoanBuilderLoan key="WhyALoanBuilderLoan" />,
        <Faqs key="Faqs" truncateCount={6} />,
        <SpeakWithOurBusinessFundingExpertsToday key="SpeakWithOurBusinessFundingExpertsToday" />,
        <ItTakesJustMinutesToCheckEligibility
          key="ItTakesJustMinutesToCheckEligibility"
          inverted
          blue
        />,
        <Footer key="Footer" />,
        <CallUsToday key="CallUsToday" />,
      ]}
    />
  </div>
);

HomePage.displayName = PageComponentName.Home;

export default HomePage;
