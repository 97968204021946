import React from 'react';
import Markdown from 'markdown-to-jsx';
import FaqQuestion from './FaqQuestion';
import CustomerSupportPhone from '../../customerSupportPhone/CustomerSupportPhone';
import './faqs.scss';

type FaqEntryProps = {
  entry: FaqQuestion;
  index: number;
  contact?: boolean;
};

const FaqEntry: React.FC<FaqEntryProps> = ({ entry, index, contact }) => (
  <div className={`ppvx_accordion__row ${!contact ? 'no-contact-row' : ''}`}>
    <button type="button" className="ppvx_accordion__header" aria-expanded="false" id={`faq-question-${index}`}>
      {index}
      .
      {' '}
      {entry.question}
    </button>
    <div className="ppvx_accordion__body" id={`faq-answer-${index}`}>
      <Markdown
        options={{
          overrides: {
            CustomerSupportPhone: {
              component: CustomerSupportPhone,
            },
          },
        }}
      >
        {entry.answer}
      </Markdown>
    </div>
  </div>
);

FaqEntry.defaultProps = {
  contact: true,
};

export default FaqEntry;
