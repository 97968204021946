import React from 'react';
import './stack.scss';

type StackLayoutProps = {
  elements: JSX.Element[];
};

const StackLayout: React.FC<StackLayoutProps> = ({ elements }) => (
  <div className="stack-layout">
    {elements}
  </div>
);

export default React.memo(StackLayout);
