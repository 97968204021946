import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';

const WebBankDisclaimer: React.FC = () => (
  <Container className="">
    <Row>
      <Col>
        <Text size="md">
          The lender for the Paycheck Protection Program Loan through PayPal is WebBank, Member
          FDIC.
        </Text>
        <Text size="md">
          <em>
            IMPORTANT NOTE: The SBA continues to produce new and revised guidance on the
            forgiveness aspects of the Paycheck Protection Program. The information on this web
            page is provided to assist PPP loan borrowers in generally understanding the process by
            which their loan may be forgiven, in whole or in part, but should not be relied upon as
            legal, tax, or financial advice. Please check the SBA website for published guidance
            and details.
          </em>
        </Text>
      </Col>
    </Row>
  </Container>
);

export default WebBankDisclaimer;
