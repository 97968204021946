import NavigationEntry from './navigationEntry';
import routes from '../../../../routes/routes';
import RouteName from '../../../../routes/routeName';
import { AppConfig } from '../../../../util/appConfig';
import resolve from '../../../../util/resolve';
import Partner from '../../../../util/partner';

function getNavigationEntries(appConfig: AppConfig, partner: Partner): NavigationEntry[] {
  return [
    { text: 'FAQs', route: resolve(routes[RouteName.FAQ].path, { partner }) },
    { text: 'Terms of Use', link: appConfig.termsOfUseUrl },
  ];
}

export default getNavigationEntries;
