import React, { useContext } from 'react';
import {
  Container, Row, Col, Text, Link,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../../util/appConfig';
import './existing-ppp-loan.scss';

const ExistingPppLoan: React.FC = () => {
  const { customerPortalUrl } = useContext(AppConfigContext);

  return (
    <Container className="existing-ppp-loan">
      <Row align="center">
        <Col>
          <Text as="h1" size="xl3" className="existing-ppp-loan__text">
            Need additional information?
          </Text>
          <Text size="lg" className="existing-ppp-loan__text">
            For the most current information on the status of your pending PPP
            loan forgiveness application
            {' '}
            <Link
              as="a"
              href={customerPortalUrl}
              className="no-line-animation existing-ppp-loan__link"
            >
              please click here to log into your customer portal account
            </Link>
            .
          </Text>
          <Text size="lg" className="existing-ppp-loan__text">
            If you are unable to log in or need other assistance, please call
            {' '}
            <Link href="tel:18667400393" className="no-line-animation existing-ppp-loan__phone">
              1-866-740-0393
            </Link>
            . Our team is available Monday through Friday from 8 a.m. to 6 p.m. ET. Our call
            centers are experiencing higher than usual call volume, so we apologize in advance
            for any longer than expected hold time as you wait to speak to a specialist.
          </Text>
        </Col>
      </Row>
      <Row align="center">
        <Col>
          <Text size="sm">
            <em>
              IMPORTANT NOTE: The SBA continues to produce new and revised guidance on the Paycheck
              Protection Program. The information on this web page is provided to assist in
              generally understanding the PPP loan program. The information on this web page should
              not be relied upon as legal, tax, or financial advice. Please check the SBA website
              for published guidance and details.
            </em>
          </Text>
          <Text size="sm">
            The lender for the Paycheck Protection Program loan through PayPal is WebBank, Member
            FDIC.
          </Text>
        </Col>
      </Row>
    </Container>
  );
};

export default ExistingPppLoan;
