import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './navEntry.scss';
import downArrowImage from './down-arrow--white.svg';
import NavigationEntry from '../navigationEntry';

type NavEntryProps = {
  navLink: NavigationEntry;
};

function getLinkContents(navLink: NavigationEntry) {
  return (
    <>
      {navLink.text}
      {(navLink.children && (
        <>
          &nbsp;
          <img className="dropdown-arrow" src={downArrowImage} alt="" />
        </>
      ))}
    </>
  );
}

function getLink(navLink: NavigationEntry) {
  if (navLink.route) {
    return (
      <a href={navLink.route} className="ppvx_text--md">
        {getLinkContents(navLink)}
      </a>
    );
  }

  return (
    <a href={navLink.link} className="ppvx_text--md">
      {getLinkContents(navLink)}
    </a>
  );
}

function getChildLink(child: NavigationEntry) {
  if (child.route) {
    return <a href={child.route}>{child.text}</a>;
  }

  return <a href={child.link}>{child.text}</a>;
}

const NavEntry: React.FC<NavEntryProps> = ({ navLink }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const flipShowSubMenu = () => setShowSubMenu(!showSubMenu);

  return (
    <li
      className={navLink.children ? 'sub-menu' : 'nav-entry'}
      onMouseEnter={flipShowSubMenu}
      onMouseLeave={flipShowSubMenu}
    >
      {getLink(navLink)}
      {navLink.children
        && (
          <CSSTransition
            in={showSubMenu}
            timeout={400}
            classNames="list-transition"
            unmountOnExit
          >
            <div className="child-links-body">
              <ul className="child-links">
                {navLink.children.map((child) => (
                  <li key={child.route} className="child-link-item">
                    {getChildLink(child)}
                  </li>
                ))}
              </ul>
            </div>
          </CSSTransition>
        )}
    </li>
  );
};

export default NavEntry;
