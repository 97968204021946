import PageComponentName from '../pages/pageComponentName';
import RouteName from './routeName';

type RouteTableEntry = {
  path: string;
  componentName: PageComponentName;
};

type RouteTable = {
  [name in RouteName]: RouteTableEntry;
};

/**
 * Don't alphabetize these. React router evaluates these from top to bottom, so it's important to
 * keep the simpler URLs at the top of this file so that they don't get misinterpreted.
 */
const routes: RouteTable = {
  home: { path: '/:partner/home', componentName: PageComponentName.Home },
  faq: { path: '/:partner/faq', componentName: PageComponentName.FAQ },
  digital: { path: '/digital', componentName: PageComponentName.Digital },
  dm: { path: '/lb/dm', componentName: PageComponentName.DirectMail },
  'customer-portal-redirect': { path: '/portal', componentName: PageComponentName.CustomerPortalRedirect },
  'partner-redirect': { path: '/partner', componentName: PageComponentName.PartnerRedirect },
  'braintree-leads-redirect': { path: '/braintree-leads', componentName: PageComponentName.BraintreeLeadsRedirect },
  'faq-sl': { path: '/lb/faq-sl', componentName: PageComponentName.SitelinkFaq },
  'more-info': { path: '/lb/more-info', componentName: PageComponentName.SitelinkMoreInfo },
  'paypal-leads-redirect': { path: '/paypal-leads', componentName: PageComponentName.PayPalLeadsRedirect },
  'paypal-business-loan': { path: '/paypal-business-loan', componentName: PageComponentName.PayPalBusinessLoan },
  'paypal-business-loan-faq': { path: '/paypal-business-loan-faq', componentName: PageComponentName.PayPalBusinessLoanFaq },
  'paypal-business-loan-more-info': { path: '/paypal-business-loan-more-info', componentName: PageComponentName.PayPalBusinessLoanMoreInfo },
  'terms-and-conditions': { path: '/terms-and-conditions', componentName: PageComponentName.TermsAndConditions },
  'partner-terms-and-conditions': { path: '/:partner/terms-and-conditions', componentName: PageComponentName.TermsAndConditions },
  'paypal-guest-application': { path: '/paypal', componentName: PageComponentName.PayPalApplicationRedirect },
  'ppp-loan': { path: '/ppp-loan', componentName: PageComponentName.PPP2 },
  'ppp-loan-application': { path: '/ppp-loan-application', componentName: PageComponentName.PPPLoanRedirect },
  'ppp-loan-info': { path: '/ppp-loan-info', componentName: PageComponentName.PPPLoanInfo },
  'ppp-loan-start': { path: '/ppp-loan-start', componentName: PageComponentName.PPPLoanStart },
  'ppp-loan-support': { path: '/ppp-loan-support', componentName: PageComponentName.PPPLoanRedirect },
  'ppp-loan-update': { path: '/ppp-loan-update', componentName: PageComponentName.PPPLoanRedirect },
  PayPalBusinessLoanDm: { path: '/paypal-business-loan/dm', componentName: PageComponentName.PayPalBusinessLoanDm },
};

export default routes;
