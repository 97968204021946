import React from 'react';
import {
  Container, Row, Col, Text, Divider, Link,
} from '@swift-paypal/pp-react';
import {
  FortyPercentBlue, SixtyPercentBlue, LessThanOneHundredBlue,
} from './index';
import './loan-proceeds-must-be-used.scss';

const LoanProceedsMustBeUsed: React.FC = () => (
  <div className="must-be-used">
    <Container className="must-be-used-container">
      <Row align="center">
        <Col className="must-be-used-header">
          <Text size="xl4">
            At least 60% of loan proceeds must be used for payroll costs and the remainder for other
            eligible expenses to achieve maximum forgiveness.
          </Text>
          <Text size="xl" regular>
            Under the Paycheck Protection Program the following rules must be followed:
          </Text>
          <Divider secondary />
        </Col>
      </Row>
      <Row align="center">
        <Col>
          <Row align="center">
            <Col xs={12} md={4}>
              <img src={SixtyPercentBlue} className="must-be-used-icon" alt="" />
              <Row>
                <Col xs={2} md={4} lg={3}>
                  <Text size="lg" className="step-image">
                    <div className="number">1</div>
                  </Text>
                </Col>
                <Col xs={10} md={8} lg={9}>
                  <Text>
                    At least 60% of the loan proceeds must be used for eligible payroll costs and/or
                    owner replacement compensation costs.
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <img src={FortyPercentBlue} className="must-be-used-icon" alt="" />
              <Row>
                <Col xs={2} md={4} lg={3}>
                  <Text size="lg" className="step-image">
                    <div className="number">2</div>
                  </Text>
                </Col>
                <Col xs={10} md={8} lg={9}>
                  <Text>
                    No more than 40% of the loan proceeds must be used for the other eligible,
                    nonpayroll costs, such as rent, utilities, mortgage interest, etc. as specified
                    above.
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <img src={LessThanOneHundredBlue} className="must-be-used-icon" alt="" />
              <Row>
                <Col xs={2} md={4} lg={3}>
                  <Text size="lg" className="step-image">
                    <div className="number">3</div>
                  </Text>
                </Col>
                <Col xs={10} md={8} lg={9}>
                  <Text>
                    If your loan is more than $50,000 and you have employees, full-time employee
                    headcount must be maintained or restored, and compensation levels can&apos;t be
                    cut by more than 25% if they make less than $100,000 to help maximize
                    forgiveness.*
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row align="center">
        <Col className="disclaimer-text">
          <Text>
            *The employee headcount retention requirement is subject to certain exemptions,
            for example, if you restore employee headcount to certain historical levels, or
            make good faith efforts to rehire employees, but they refuse, or if your
            business was unable to operate at historical levels due to compliance with
            Covid-19 related government rules or requirements. Exemption and safe harbors
            may apply.
          </Text>
          <Link
            className="no-line-animation"
            href="https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/paycheck-protection-program/ppp-loan-forgiveness#section-header-4"
            rel="noreferrer noopener"
            target="_blank"
          >
            Additional details and information can be found on the SBA&apos;s website.
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
);

export default LoanProceedsMustBeUsed;
