import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import generateApplicationStartUrl from '../../util/generateApplicationStartUrl';
import { AppConfigContext } from '../../util/appConfig';
import { redirect } from '../../util/redirect';
import LeadForm from './leadForm';
import mapLeadFormToProduct from './mapLeadFormToProduct';

export type LeadFormRedirectProps = {
  leadForm: LeadForm;
};

const LeadFormRedirect: React.FC<LeadFormRedirectProps> = ({ leadForm }) => {
  const { search } = useLocation();
  const { dynamicAppBaseUrl } = useContext(AppConfigContext);
  const product = mapLeadFormToProduct(leadForm);
  const url = generateApplicationStartUrl(dynamicAppBaseUrl, product, {
    search,
  });

  redirect(url);

  return <></>;
};

export default LeadFormRedirect;
