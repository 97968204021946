import React, { useContext } from 'react';
import {
  Col, Container, Row, Text,
} from '@swift-paypal/pp-react';
import PPPLoanInfoBullet from '../PPPLoanInfoBullet/PPPLoanInfoBullet';
import {
  ContractorCertBlue,
  DebtInterestIncurredBlue,
  PayrollBlue,
  PondAndTreeBlue,
  RentBlue,
  UtilsBlue,
  WalletBlue,
  WTwoBlue,
} from './index';
import { AppConfigContext } from '../../../util/appConfig';
import './eligible-expenses.scss';

const EligibleExpenses: React.FC = () => {
  const { forgiveness3508SEnhancedEnabled } = useContext(AppConfigContext);

  return (
    <>
      <div className="eligible-expenses gray">
        <Container className="eligible-expenses__container">
          <Row align="center">
            <Col>
              <Text size="xl4" as="h1" className="eligible-expenses__header">
                {forgiveness3508SEnhancedEnabled ? 'C' : 'Recent c'}
                hanges to PPP Loan Forgiveness
              </Text>
              {!forgiveness3508SEnhancedEnabled && (
                <>
                  <Text size="xl">
                    <strong>
                      Loans of $150,000 or less can use the new SBA Form 3508S which has several
                      benefits:
                    </strong>
                  </Text>
                  <Text as="ul" size="xl" className="eligible-expenses__list condense-top-margin">
                    <li>
                      Single page application
                    </li>
                    <li>
                      Supporting documentation is not required to be submitted with your loan
                      forgiveness application
                    </li>
                    <li>
                      For second draw loans, Gross Receipt Reduction documentation will be required,
                      if not submitted at time of loan application
                    </li>
                  </Text>
                </>
              )}
              <Text size="xl">
                <strong>
                  New types of expenses are now eligible for loan forgiveness. These new expense
                  types include:
                </strong>
              </Text>
              <Text size="xl" as="ul" className="eligible-expenses__list condense-top-margin">
                <li>
                  Employer contributions for group health, life, disability, vision and dental
                  insurance and retirement benefits, including insurance premiums
                </li>
                <li>
                  Operations expenditures
                </li>
                <li>
                  Property damage costs
                </li>
                <li>
                  Supplier costs
                </li>
                <li>
                  Worker protection expenditures
                </li>
              </Text>
              <Text size="xl">
                <strong>
                  The timeframe to use PPP loan proceeds on forgiveness eligible expenses is more
                  flexible, allowing borrowers to choose a period between 8 and 24 weeks from loan
                  disbursement.
                </strong>
              </Text>
              <Text size="xl">
                <strong>
                  EIDL Advance amounts are no longer deducted from loan forgiveness.
                </strong>
              </Text>
            </Col>
          </Row>
          <Row align="center">
            <Col>
              <Text size="xl4" as="h1" className="eligible-expenses__header">
                Eligible forgivable expenses include:
              </Text>
              <Row>
                <Col lg={3} md={6} sm={12}>
                  <PPPLoanInfoBullet
                    icon={PayrollBlue}
                    content={(
                      <ul className="eligible-expenses__list">
                        <li>
                          <Text size="md">
                            Eligible full-time employee equivalent payroll expenses.
                          </Text>
                        </li>
                        <li>
                          <Text size="md">
                            Owner replacement compensation for self-employed individuals and
                            independent contractors.
                          </Text>
                        </li>
                      </ul>
                    )}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <PPPLoanInfoBullet
                    icon={DebtInterestIncurredBlue}
                    content={(
                      <Text size="md">
                        Eligible interest payments on qualifying business mortgages entered into
                        before February 15, 2020.
                      </Text>
                    )}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <PPPLoanInfoBullet
                    icon={RentBlue}
                    content={(
                      <Text size="md">
                        Eligible business rent or lease payments for real or personal property,
                        under business lease agreements entered into before February 15, 2020.
                      </Text>
                    )}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <PPPLoanInfoBullet
                    icon={UtilsBlue}
                    content={(
                      <Text size="md">
                        Eligible utility payments, for which service began before February 15, 2020
                        including electricity, gas, water, transportation, telephone, and internet.
                      </Text>
                    )}
                  />
                </Col>
              </Row>
              <Text size="lg" className="eligible-expenses__subheader">
                See above for new additional expenses.
              </Text>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="eligible-expenses">
        <Container className="eligible-expenses__container">
          <Row align="center">
            <Col>
              <Text size="xl4" as="h1" className="eligible-expenses__header">
                As a reminder, eligible payroll expenses generally include:
              </Text>
              <Row>
                <Col lg={3} md={6} sm={12}>
                  <PPPLoanInfoBullet
                    icon={WalletBlue}
                    content={(
                      <Text size="md">
                        Salary, wages, commissions, hazard pay, bonuses, or tips for full-time
                        equivalent employees working or furloughed (excluding, on a prorated basis,
                        any employee compensation over $100,000 annually).
                      </Text>
                    )}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <PPPLoanInfoBullet
                    icon={ContractorCertBlue}
                    content={(
                      <Text size="md">
                        Owner replacement compensation for individuals who report self-employment
                        income, typically applicable for sole proprietors, self-employed
                        individuals,
                        independent contractors, and general partners.
                      </Text>
                    )}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <PPPLoanInfoBullet
                    icon={PondAndTreeBlue}
                    content={(
                      <Text size="md">
                        Employee benefits, for example, costs for vacation, parental, family,
                        medical,
                        or sick leave; payments for group health, life, disability, vision and
                        dental
                        insurance and retirement benefits, including insurance premiums.
                      </Text>
                    )}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <PPPLoanInfoBullet
                    icon={WTwoBlue}
                    content={(
                      <Text size="md">
                        State and local taxes paid by you on employee compensation.
                      </Text>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EligibleExpenses;
