import React, { useContext } from 'react';
import {
  Container, Row, Col, Text, Button,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../../util/appConfig';
import './mobile-hero-text.scss';

const MobileHeroText: React.FC = () => {
  const { customerPortalUrl, ppp21LoFoEnabled } = useContext(AppConfigContext);
  return (
    <Container className="ppp-loan-info-mobile-hero">
      <Row align="center">
        <Col sm={12}>
          {ppp21LoFoEnabled && (
            <Text size="xl" className="ppp-loan-info-mobile-hero__text-block">
              We are now accepting and processing loan forgiveness applications using the new
              SBA Form 3508S, 3508, and 3508EZ for loans funded in 2020 and 2021.
            </Text>
          )}
          {!ppp21LoFoEnabled && (
            <>
              <Text size="xl" className="ppp-loan-info-mobile-hero__text-block">
                We are now accepting and processing loan forgiveness applications using the new
                SBA Form 3508S, 3508, and 3508EZ for loans funded in 2020.
              </Text>
              <Text size="xl" className="ppp-loan-info-mobile-hero__text-block">
                We currently expect to begin accepting loan forgiveness applications for loans
                funded in 2021 in July.
              </Text>
            </>
          )}
          <Button
            as="a"
            href={customerPortalUrl}
            className="ppp-loan-info-mobile-hero__btn no-line-animation"
          >
            Log in to Get Started
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default MobileHeroText;
