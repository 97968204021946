import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageComponentName from '../pageComponentName';
import PpblHeader from '../../components/payPalBusinessLoan/header/Header';
import Hero from '../../components/common/hero/Hero';
import HeroContent from '../../components/payPalBusinessLoanFaq/heroContent/HeroContent';
import Faqs from '../../components/common/content/faqs/Faqs';
import MobileSubHero from '../../components/payPalBusinessLoanFaq/mobileSubHero/MobileSubHero';
import PpblCheckEligibility from '../../components/payPalBusinessLoanFaq/checkEligibility/PpblCheckEligibility';
import PpblFooter from '../../components/payPalBusinessLoan/footer/PpblFooter';
import StackLayout from '../../layouts/stack/Stack';
import './paypal-business-loan-faq.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const questions = require('../../components/payPalBusinessLoanFaq/questions.yaml');

const PayPalBusinessLoanFaqPage: React.FC = () => (
  <div className="ppbl-faq-page">
    <Helmet meta={[
      <meta name="robots" content="noindex, nofollow" />,
    ]}
    >
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout
      elements={[
        <Hero
          key="Hero"
          header={<PpblHeader hideLogIn hideSignUp />}
          content={<HeroContent />}
          backgroundClass={HeroContent.backgroundClass}
        />,
        <MobileSubHero key="MobileSubHero" />,
        <Faqs
          colorScheme="white"
          contact={false}
          displayTitle={false}
          entries={questions}
          key="faqs"
        />,
        <PpblCheckEligibility
          key="ItTakesJustMinutesToCheckEligibility"
        />,
        <PpblFooter key="PpblFaqfooter" />,
      ]}
    />
  </div>
);

PayPalBusinessLoanFaqPage.displayName = PageComponentName.PayPalBusinessLoanFaq;

export default PayPalBusinessLoanFaqPage;
