import React, { useContext } from 'react';
import {
  Container, Row, Button, Col,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../../util/appConfig';
import './header.scss';

type PpblHeaderProps = {
  blue?: boolean;
  hideLogIn?: boolean;
  hideSignUp?: boolean;
};

const PpblHeader: React.FC<PpblHeaderProps> = ({
  blue = false,
  hideLogIn = false,
  hideSignUp = false,
}) => {
  const { customerPortalUrl } = useContext(AppConfigContext);
  const loginLink = hideSignUp ? customerPortalUrl : 'https://www.paypal.com/us/signin';
  const logoSource = blue ? 'https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-color.svg' : 'https://www.paypalobjects.com/digitalassets/c/paypal-ui/logos/svg/paypal-monotone-transparent.svg';
  const mobileLogoSource = blue ? 'https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg' : 'https://www.paypalobjects.com/digitalassets/c/paypal-ui/logos/svg/paypal-mark-monotone-transparent.svg';

  return (
    <section role="banner" className="header ppbl">
      <Container>
        <Row className="desktop-content">
          <Col xs={8} className="logos">
            <img
              className="logo"
              src={logoSource}
              alt="PayPal Logo"
            />
          </Col>
          <Col xs={4}>
            <ul className="log-in right">
              {!hideLogIn && (
                <li>
                  <Button
                    as="a"
                    className={`no-line-animation ${blue ? '' : 'ppvx_btn--secondary ppvx_btn--inverse'}`}
                    href={loginLink}
                  >
                    Log In
                  </Button>
                </li>
              )}
              <li>
                {!hideSignUp && (
                  <Button
                    as="a"
                    className="no-line-animation ppvx_btn--inverse"
                    href="https://www.paypal.com/us/webapps/mpp/account-selection"
                  >
                    Sign Up
                  </Button>
                )}
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mobile-content">
          <Col sm={12} className="mobile-content-column">
            <img
              className="logo"
              alt="PayPal"
              src={mobileLogoSource}
            />
          </Col>
          <Col xs={10} />
        </Row>
      </Container>
    </section>
  );
};

export default PpblHeader;
