import React from 'react';
import {
  BodyText,
  Col,
  Container,
  Row,
} from '@swift-paypal/pp-react';
import CheckEligibilityButton from '../../common/checkEligibilityButton/CheckEligibilityButton';
import './mobile-sub-hero.scss';

const MobileSubHero: React.FC = () => (
  <div className="content-block mobile-sub-hero">
    <Container as="section">
      <Row>
        <Col>
          <BodyText className="sub-header-text">
            See below for answers to frequently asked questions.
          </BodyText>
        </Col>
      </Row>
      <Row className="button-section">
        <Col>
          <CheckEligibilityButton ppblRedirect />
        </Col>
      </Row>
    </Container>
  </div>
);

export default MobileSubHero;
