import axios from 'axios';

async function validateSourceCode(sourceCode: string, apiUrl: string) {
  const res = await axios.get(`${apiUrl}/campaigns?sourceCode=${sourceCode}`);
  return res.data;
}

async function validateIdentity(contactIdentity: string, apiUrl: string) {
  const res = await axios.get(`${apiUrl}/customer-information/${contactIdentity}`);
  return res.data;
}

export default {
  validateSourceCode,
  validateIdentity,
};
