import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';
import './speakWithOurBusinessFundingExpertsToday.scss';
import CustomerSupportPhone from '../../customerSupportPhone/CustomerSupportPhone';

const SpeakWithOurBusinessFundingExpertsToday: React.FC = () => (
  <div className="content-block inverted speak-with-our-business-funding-experts-today">
    <Container>
      <Row>
        <Col>
          <Text size="xl4" as="h2">Speak with our team today.</Text>
          <Text size="md">
            Our U.S.-based team is available Monday – Friday,
            9 a.m. – 8 p.m. ET and Saturday, 11 a.m. – 3 p.m. ET.
            <br />
            Please call us at
            {' '}
            <CustomerSupportPhone as="span" />
          </Text>
        </Col>
      </Row>
    </Container>
  </div>
);

export default SpeakWithOurBusinessFundingExpertsToday;
