import React, { useState } from 'react';
import {
  Container, Row, Col, Text, Button,
} from '@swift-paypal/pp-react';
import useInterval from 'use-interval';
import step1 from './Step 1.svg';
import step2 from './Step 2.svg';
import step3 from './Step 3.svg';
import step4 from './Step 4.svg';
import './howTheApplicationProcessWorks.scss';

type Slide = {
  id: string;
  image: string;
  text: string;
  alt: string;
};

const slides: Slide[] = [
  {
    id: 'slide-1',
    image: step1,
    text: 'Provide your information through our online portal. This Includes your average monthly payroll Information which will be used to calculate your loan amount.',
    alt: 'Storefront',
  },
  {
    id: 'slide-2',
    image: step2,
    text: 'Once the application has been submitted and your preliminary eligibility is confirmed, we will request via email the supporting documentation required to continue processing your loan request.',
    alt: 'Envelope',
  },
  {
    id: 'slide-3',
    image: step3,
    text: 'Upload the requested documents in our online portal.',
    alt: 'Cloud',
  },
  {
    id: 'slide-4',
    image: step4,
    text: 'If you are approved, we will send you loan documentation (Loan Agreement and Note) and the official SBA PPP loan application form to sign. After your signed and completed documents are received and we confirm that no other PPP loan has been issued, we will send your application to the SBA for approval. Once we receive SBA approval, the loan funds will be transferred to the bank account you specified in the loan documentation.',
    alt: 'Checkmark',
  },
];

const HowTheApplicationProcessWorks = React.forwardRef<HTMLDivElement>((props, ref) => {
  const slideInterval = 5000; // milliseconds
  const [slideId, setSlideId] = useState(slides[0].id);
  const [autoplay, setAutoplay] = useState(true);

  const selectSlide = (newSlideId: string) => {
    setAutoplay(false);
    setSlideId(newSlideId);
  };

  useInterval(() => {
    if (!autoplay) {
      return;
    }

    let nextSlideIndex = slides.findIndex(((s) => s.id === slideId)) + 1;

    while (nextSlideIndex >= slides.length) {
      nextSlideIndex -= slides.length;
    }

    const nextSlide = slides[nextSlideIndex];
    setSlideId(nextSlide.id);
  }, slideInterval);

  return (
    <div ref={ref} className="content-block how-the-application-process-works">
      <Container>
        <Row>
          <Col>
            <Text size="xl4" as="h2">
              How the application process works:
            </Text>
            <Text size="lg" as="h3" regular>
              The final day for PayPal to send PPP loan applications to the SBA is June 30, 2020.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {slides.map((slide, index) => (
              <Button
                key={`${slide.id}-button`}
                as="div"
                className={`ppvx_text--lg slide-button ${slide.id === slideId ? 'selected-slide-button' : ''}`}
                onClick={() => selectSlide(slide.id)}
              >
                <div className="number">{index + 1}</div>
                <div className="text">{slide.text}</div>
              </Button>
            ))}
          </Col>
          <Col className="slide-container" md={6}>
            {slides.map((slide) => (
              <img
                key={`${slide.id}-image`}
                src={slide.image}
                alt={slide.alt}
                className={`slide-image ${slide.id === slideId ? 'selected-slide-image' : ''}`}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default HowTheApplicationProcessWorks;
