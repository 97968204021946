import React, {
  useState,
  useEffect,
  useContext,
  FormEvent,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  HeadingText, Container, TextInput, Button, Col, Row,
} from '@swift-paypal/pp-react';
import WhereIsThis from './WhereIsThis';
import InvalidFundingCodeModal from '../invalidFundingCodeModal/InvalidFundingCodeModal';
import validationService from './ValidationService';
import { AppConfigContext } from '../../../util/appConfig';
import { redirect } from '../../../util/redirect';
import './funding-code-validation.scss';
import generateApplicationStartUrlByPartner
  from '../../../util/generateApplicationStartUrlByPartner';
import StorageKey from '../../../util/storageKey';
import usePartner from '../../../util/usePartner';

const sourceCodePattern = /[\w\d]{5}/;
const identityPattern = /([cC|lL][0-9]{8})/;

const FundingCodeValidation: React.FC = () => {
  const { apiUrl, dynamicAppBaseUrl } = useContext(AppConfigContext);
  const partner = usePartner();

  const [sourceCode, setSourceCode] = useState('');
  const [sourceCodeValid, setSourceCodeValid] = useState(false);
  const [identity, setIdentity] = useState('');
  const [identityValid, setIdentityValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const { search } = useLocation();
  const leadOriginUrl = sessionStorage.getItem(StorageKey.LeadOriginUrl) as string;
  const validateCode = async (event: FormEvent) => {
    event.preventDefault();
    if (sourceCodeValid && identityValid) {
      const url = generateApplicationStartUrlByPartner(dynamicAppBaseUrl, partner, leadOriginUrl, {
        query: {
          identity,
          sourceCode,
          directMail: 1,
        },
        search,
      });
      redirect(url);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (!sourceCodePattern.test(sourceCode)) {
      setSourceCodeValid(false);
      return;
    }

    const validateSourceCode = async () => {
      try {
        await validationService.validateSourceCode(sourceCode, apiUrl);
        setSourceCodeValid(true);
      } catch (e) {
        setSourceCodeValid(false);
      }
    };

    validateSourceCode();
  }, [apiUrl, sourceCode]);

  useEffect(() => {
    setIdentityValid(identityPattern.test(identity));
  }, [identity]);

  return (
    <Container className="funding-code" fluid>
      <Row>
        <Col className="funding-code-form">
          <HeadingText
            size="md"
            className="funding-code__label"
          >
            Funding Code From Mailer:
          </HeadingText>
        </Col>
      </Row>
      <Row>
        <Col
          lg={4}
          md={6}
          xs={12}
        >
          <Row>
            <Col className="funding-code__field-container">
              <TextInput
                autoComplete="disabled"
                className="first-field"
                id="sourceCode"
                isLabelHidden
                label="First Set of Funding Code"
                maxLength={5}
                multiline={false}
                name="sourceCode"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSourceCode(e.target.value)}
                placeholder="XXXXX"
                type="text"
              />
              <span className="dash--offer">
                -
              </span>
              <TextInput
                autoComplete="disabled"
                className="second-field"
                id="identity"
                isLabelHidden
                label="Second Set of Funding Code"
                maxLength={9}
                multiline={false}
                name="identity"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIdentity(e.target.value)}
                placeholder="XXXXXXXXX"
                type="text"
              />
            </Col>
          </Row>
          <WhereIsThis />
        </Col>
        <Col md={6} xs={12} className="funding-code__submit-button">
          <Button
            onClick={validateCode}
            className="check-eligibility-button no-line-animation"
          >
            Check Eligibility
          </Button>
        </Col>
      </Row>
      {
        showError
        && (
          <InvalidFundingCodeModal
            show={showError}
            onClose={() => setShowError(false)}
          />
        )
      }
    </Container>
  );
};

export default FundingCodeValidation;
