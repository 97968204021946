import React from 'react';
import { Helmet } from 'react-helmet-async';
import PpblHeader from '../../../components/payPalBusinessLoan/header/Header';
import PageComponentName from '../../pageComponentName';
import ExistingPppLoan from '../../../components/ppp2/existingPppLoan/ExistingPppLoan';
import FundingUnavailable from '../../../components/ppp2/FundingUnavailable/FundingUnavailable';
import Hero from '../../../components/common/hero/Hero';
import Ppp2Hero from '../../../components/ppp2/ppp2HeroContent/Ppp2Hero';
import PppLoanBasics from '../../../components/ppp2/pppLoanBasics/PppLoanBasics';
import Ppp2MobileHeroText from '../../../components/ppp2/ppp2HeroContent/Ppp2MobileHeroText';
import StackLayout from '../../../layouts/stack/Stack';
import './ppp2.scss';

const PPP2Page: React.FC = () => (
  <>
    <Helmet>
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout elements={[
      <Hero
        key="Hero"
        header={<PpblHeader hideSignUp />}
        content={(
          <Ppp2Hero />
        )}
        backgroundClass={Ppp2Hero.backgroundClass}
      />,
      <Ppp2MobileHeroText key="MobileHeroText" />,
      <FundingUnavailable key="FundingUnavailable" />,
      <PppLoanBasics key="PPPLoanBasics" />,
      <ExistingPppLoan key="ExistingPPPLoan" />,
    ]}
    />
  </>
);

PPP2Page.displayName = PageComponentName.PPP2;

export default PPP2Page;
