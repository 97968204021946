import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageComponentName from './pageComponentName';
import StackLayout from '../layouts/stack/Stack';
import Faqs from '../components/common/content/faqs/Faqs';
import Hero from '../components/common/hero/Hero';
import FaqHeroContent from '../components/faq/faqHeroContent/FaqHeroContent';
import ItTakesJustMinutesToCheckEligibility from '../components/common/content/itTakesJustMinutesToCheckEligibility/ItTakesJustMinutesToCheckEligibility';
import Footer from '../components/common/footer/Footer';
import CallUsToday from '../components/common/content/callUsToday/CallUsToday';

const FaqsPage: React.FC = () => (
  <>
    <Helmet>
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout
      elements={[
        <Hero
          key="Hero"
          content={<FaqHeroContent />}
          backgroundClass={FaqHeroContent.backgroundClass}
        />,
        <Faqs key="Faqs" />,
        <ItTakesJustMinutesToCheckEligibility
          key="ItTakesJustMinutesToCheckEligibility"
          inverted
          blue
        />,
        <Footer key="Footer" />,
        <CallUsToday key="CallUsToday" />,
      ]}
    />
  </>
);

FaqsPage.displayName = PageComponentName.FAQ;

export default FaqsPage;
