import React from 'react';

const innerComponentNameRegex = /^[\w\d]*\((.*)\)$/;

export default function getDisplayName(wrappedComponent: React.ComponentType) {
  return wrappedComponent.displayName || wrappedComponent.name || wrappedComponent.constructor.name || 'Component';
}

export function getInnerDisplayName(wrappedComponent: React.ComponentType) {
  let name = getDisplayName(wrappedComponent);
  while (name) {
    const match = name.match(innerComponentNameRegex);
    if (!match || match.length < 2) {
      break;
    }

    [, name] = match;
  }

  return name;
}
