import React from 'react';
import {
  Container, Text, Col, Row,
} from '@swift-paypal/pp-react';
import './ppblFooter.scss';

type PpblFooterProps = {
  international?: boolean;
};

const PpblFooter: React.FC<PpblFooterProps> = ({ international }) => (
  <div className="footer ppbl-footer">
    <Container>
      <Row>
        <Col>
          <Text size="sm" className="padding-bottom">
            {
                international ? (
                  <>
                    In the US, the lender for PayPal Business Loan is WebBank, Member FDIC.
                    <br />
                    In Canada, the lender for PayPal Business Loan is PayPal Canada Co.
                  </>
                ) : (
                  <>The lender for PayPal Business Loan is WebBank, Member FDIC.</>
                )
              }
          </Text>
        </Col>
      </Row>
    </Container>
  </div>
);

export default PpblFooter;
