import React from 'react';
import { Link } from '@swift-paypal/pp-react';
import './sba-link.scss';

const SBALink: React.FC = () => (
  <Link
    href="https://www.sba.gov/funding-programs/loans/paycheck-protection-program-ppp"
    className="no-line-animation sba-link"
  >
    Click here to access all the details directly from the SBA.
  </Link>
);

export default SBALink;
