import React from 'react';
import {
  BodyText,
  CaptionText,
  Col,
  Container,
  HeadingText,
  Row,
} from '@swift-paypal/pp-react';
import CheckEligibilityButton from '../../common/checkEligibilityButton/CheckEligibilityButton';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import './sitelinkMoreInfoHeroContent.scss';

const SitelinkMoreInfoHeroContent: HeroContentComponent = () => (
  <Container as="section" className="sitelink-more-info-hero">
    <Row>
      <Col sm={12}>
        <HeadingText as="h2" className="ppvx_text--xl4 sitelink-more-info-hero__content">
          Build the loan that&apos;s right for your business.
        </HeadingText>
        <BodyText className="sitelink-more-info-hero__content">
          Single fixed fee pricing and no origination fee.*
        </BodyText>
        <CheckEligibilityButton className="sitelink-more-info-hero__button" />
        <CaptionText className="sitelink-more-info-hero__caption">
          *There is no fee deducted upfront from the loan proceeds. Instead, the single fixed fee
          (Total Loan Fee) is paid over the life of the loan. In addition to the Total Loan Fee,
          the only other cost is a $20 Returned Item Fee that is only assessed if a payment is
          returned.
        </CaptionText>
      </Col>
    </Row>
  </Container>
);

SitelinkMoreInfoHeroContent.backgroundClass = 'hero-ppbl-background';

export default SitelinkMoreInfoHeroContent;
