import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { currentLocation } from '../../util/redirect';

type PageRedirectProps = {
  to: string;
};

const PageRedirect: React.FC<PageRedirectProps> = ({
  to,
}) => {
  const { search } = useLocation();
  return (
    <Navigate
      to={{ pathname: to, search }}
      state={{ referrer: currentLocation }}
    />
  );
};

export default PageRedirect;
