import TagManager from 'react-gtm-module';

const getCookie = (cookiePrefsString) => {
  let cookiePrefsValueString;
  const match = document.cookie.match(
    new RegExp(`(^| )${cookiePrefsString || 'cookie_prefs'}=([^;]+)`),
  );
  if (match) {
    // eslint-disable-next-line prefer-destructuring
    cookiePrefsValueString = match[2];
  }
  return cookiePrefsValueString;
};

const getCookiePrefValue = (cookiePrefsString) => {
  const cookiePrefsValueString = getCookie(cookiePrefsString);
  const cookiePrefsValue = cookiePrefsValueString
      && decodeURIComponent(cookiePrefsValueString)
      && decodeURIComponent(cookiePrefsValueString)
        .split(',')
        .reduce((acc, val) => {
          const [k, v] = val.split('=');
          if (k && v) {
            acc[k] = v;
          }
          return acc;
        }, {});
  return cookiePrefsValue;
};

const initialize = (tagManagerArgs) => {
  const dataNode = document.getElementById('policy-data');
  const policyInfo = dataNode && dataNode.innerHTML ? JSON.parse(dataNode.innerHTML) : {};
  const { tenantData = {} } = policyInfo;

  const cookiePrefsValue = getCookiePrefValue(tenantData.cookiePrefsString);

  if (cookiePrefsValue) {
    if (cookiePrefsValue && cookiePrefsValue.T === '1') {
      TagManager.initialize(tagManagerArgs);
    }
  }
};

export default initialize;
