import React, { useContext } from 'react';
import {
  Container, Row, Col, Text, Button,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../../util/appConfig';
import './who-can-use-3508s.scss';

const WhoCanUse3508S: React.FC = () => {
  const { customerPortalUrl, forgiveness3508SEnhancedEnabled } = useContext(AppConfigContext);

  return (
    <>
      {forgiveness3508SEnhancedEnabled && (
      <Container className="who-can-use-3508s">
        <Row align="center">
          <Col md={10}>
            <Text size="xl4" as="h1" className="who-can-use-3508s__header">
              Who can use the new SBA Forgiveness Application Form 3508S?
            </Text>
            <Text as="ul" size="xl">
              <li>Borrowers with loans of $150,000 or less;</li>
              <li>
                Who have completed their loan forgiveness covered period following loan disbursement
                and have used all of the loan funds for which the borrower plans to request
                forgiveness;
              </li>
              <li>
                Who have not already received loan forgiveness on their PPP loan or a decision from
                the SBA regarding their loan forgiveness application
              </li>
            </Text>
            <Text size="xl4" as="h1" className="who-can-use-3508s__header">
              What are the benefits of the new SBA Forgiveness Application Form 3508S?
            </Text>
            <Text as="ul" size="xl">
              <li>
                You only need to provide a few pieces of information through our online portal to
                complete the simplified, one page application
              </li>
              <li>
                Supporting documentation is not required to be submitted with your loan forgiveness
                application
                <sup>*</sup>
              </li>
              <li>
                Loans of $50,000 or less are not subject to reductions in loan forgiveness amount
                due to reductions in employees or their salaries, hourly wages, or paid hours
              </li>
            </Text>
          </Col>
        </Row>

        <Row align="center">
          <Col sm={12}>
            <Button
              as="a"
              className="who-can-use-3508s__button no-line-animation"
              href={customerPortalUrl}
            >
              Log in to Get Started
            </Button>
          </Col>
        </Row>

        <Row align="center">
          <Col md={10}>
            <Text size="xl">
              If your loan is greater than $150,000, you will need to complete either the SBA
              Form 3508 or 3508EZ loan forgiveness application. Once you log in, we&apos;ll ask you
              a few questions to help determine which form is right for you.
            </Text>
            <Text size="md">
              <sup>*</sup>
              Gross Receipt Reduction documentation will be required to support second draw loan
              eligibility if not submitted at the time of loan application.
            </Text>
          </Col>
        </Row>
      </Container>
      )}
    </>
  );
};

export default WhoCanUse3508S;
