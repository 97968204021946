import React from 'react';
import { Helmet } from 'react-helmet-async';
import Faqs from '../../../components/common/content/faqs/Faqs';
import Footer from '../../../components/common/footer/Footer';
import Hero from '../../../components/common/hero/Hero';
import PageComponentName from '../../pageComponentName';
import Header from '../../../components/common/header/Header';
import StackLayout from '../../../layouts/stack/Stack';
import { questions, SitelinkCheckEligibility, SitelinkFaqHeroContent } from '../../../components/sitelinkFaq';

const SitelinkFaqPage: React.FC = () => (
  <>
    <Helmet meta={[
      <meta name="robots" content="noindex, nofollow" />,
    ]}
    >
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout
      elements={[
        <Hero
          key="Hero"
          header={<Header hideNav />}
          content={(
            <SitelinkFaqHeroContent key="FaqHeroContent" />
          )}
          backgroundClass={SitelinkFaqHeroContent.backgroundClass}
        />,
        <Faqs
          colorScheme="white"
          contact={false}
          displayTitle={false}
          entries={questions}
          key="faqs"
        />,
        <SitelinkCheckEligibility key="CheckEligibility" grey />,
        <Footer key="Footer" />,
      ]}
    />
  </>
);

SitelinkFaqPage.displayName = PageComponentName.SitelinkFaq;

export default SitelinkFaqPage;
