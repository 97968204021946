import React from 'react';
import { Link, useParams } from 'react-router-dom';
import routes from '../../../routes/routes';
import RouteName from '../../../routes/routeName';
import Partner from '../../../util/partner';
import './mailer.scss';
import lbMailer from './lb-mailer.svg';

const Mailer: React.FC = () => {
  const { partner } = useParams() as { partner: Partner };
  if (partner !== Partner.LoanBuilder) {
    return <div />;
  }

  return (
    <div className="mailer">
      <img src={lbMailer} alt="" />
      <div>
        Did you receive mail from us?
        &nbsp;
        <Link className="underline" to={routes[RouteName.DirectMail].path}>Click Here</Link>
      </div>
    </div>
  );
};

export default Mailer;
