/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import {
  Routes, Route, useLocation,
} from 'react-router-dom';
import getRoutes from './routesFactory';
import TrailingSlashRedirect from './routes/TrailingSlashRedirect';
import routes from './routes/routes';
import RouteName from './routes/routeName';
import resolve from './util/resolve';
import Partner from './util/partner';
import PageRedirect from './components/common/PageRedirect';
import { redirect } from './util/redirect';
import { AppConfigContext } from './util/appConfig';

const NewLandingRedirect = () => {
  const { pathname } = useLocation();

  const RouteExceptions = ['/partner', '/portal', '/digital', '/digital/', '/paypal/terms-and-conditions', '/loanbuilder/terms-and-conditions'];
  const isRedirectRoute = !RouteExceptions.includes(pathname);
  const { customerPortalUrl } = useContext(AppConfigContext);
  const paypalLoanbuilderQAURl = 'https://www.msmaster.qa.paypal.com/merchant-lending/loanbuilder/';
  const paypalLoanbuilderProdURl = 'https://www.paypal.com/merchant-lending/loanbuilder/';

  if (isRedirectRoute) {
    if (customerPortalUrl !== 'https://my.loanbuilder.com/') {
      redirect(paypalLoanbuilderQAURl);
    } else {
      redirect(paypalLoanbuilderProdURl);
    }
  }

  // Render the route with the provided component
  return (
    isRedirectRoute ? null
      : (
        <Routes>
          <Route path="(.+)/" element={<TrailingSlashRedirect />} />
          {getRoutes()}
          <Route path="/digital-chart" element={<PageRedirect to={routes.digital.path} />} />
          <Route path="/digital-faq" element={<PageRedirect to={routes.digital.path} />} />
          <Route path="/digital-vsl" element={<PageRedirect to={routes.digital.path} />} />
          <Route path="/digital-quote" element={<PageRedirect to={routes.digital.path} />} />
          <Route path="/fundme" element={<PageRedirect to={routes.digital.path} />} />
          <Route path="/nofee" element={<PageRedirect to={routes.digital.path} />} />
          <Route path="/dm" element={<PageRedirect to={routes.dm.path} />} />
          <Route
            path="/braintree-leads"
            element={<PageRedirect to={routes[RouteName.BraintreeLeadsRedirect].path} />}
          />
          <Route path="/ppploan" element={<PageRedirect to={routes[RouteName.PPP2].path} />} />
          <Route
            path="/paypal-leads"
            element={<PageRedirect to={routes[RouteName.PayPalLeadsRedirect].path} />}
          />
          <Route
            path="/portal"
            element={<PageRedirect to={routes[RouteName.CustomerPortalRedirect].path} />}
          />
          <Route
            path="/app"
            element={<PageRedirect to={resolve(routes.home.path, { partner: Partner.App })} />}
          />
          <Route
            path="/lb"
            element={
              <PageRedirect to={resolve(routes.home.path, { partner: Partner.LoanBuilder })} />
      }
          />
          <Route
            path="/*"
            element={
              <PageRedirect to={resolve(routes.home.path, { partner: Partner.LoanBuilder })} />
      }
          />
        </Routes>
      )
  );
};

export default NewLandingRedirect;
