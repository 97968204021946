import React, { useContext } from 'react';
import getNavigationEntries from './getNavigationEntries';
import NavEntry from './navEntry/NavEntry';
import { AppConfigContext } from '../../../../util/appConfig';
import './navigation.scss';
import usePartner from '../../../../util/usePartner';

const Navigation: React.FC = () => {
  const partner = usePartner();
  const appConfig = useContext(AppConfigContext);
  const navEntries = getNavigationEntries(appConfig, partner);

  return (
    <ul role="navigation" className="nav-links">
      {(navEntries.map((entry) => <NavEntry key={entry.text} navLink={entry} />))}
    </ul>
  );
};

export default Navigation;
