import React from 'react';
import { Text } from '@swift-paypal/pp-react';
import ApplyNowButton from '../pppLoanApplication/applyNowButton/ApplyNowButton';

export type PPPLoanSupportHeroProps = {
  className?: string;
  mobile?: boolean;
};

const PPPLoanSupportHero: React.FC<PPPLoanSupportHeroProps> = (props) => {
  const { className, mobile } = props;
  return (
    <>
      <Text className={className} size="md">
        Limited funding has been allocated by the US Government for the Paycheck Protection Program.
        We will continue to accept new loan applications and process existing applications for
        submission to the SBA, subject to funding availability.
      </Text>
      <div className="hero-apply-now">
        <ApplyNowButton mobile={mobile} />
      </div>
      <Text size="sm" className={className}>
        * The lender for the Paycheck Protection Program
        <br />
        loan through PayPal is WebBank, Member FDIC.
      </Text>
    </>
  );
};

export default PPPLoanSupportHero;
