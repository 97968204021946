import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import generateApplicationStartUrl from '../util/generateApplicationStartUrl';
import { AppConfigContext } from '../util/appConfig';
import { redirect } from '../util/redirect';
import DynamicProduct from '../util/dynamicProduct';
import PageComponentName from './pageComponentName';

const PayPalApplicationRedirectPage: React.FC = () => {
  const { search } = useLocation();
  const { dynamicAppBaseUrl } = useContext(AppConfigContext);
  const url = generateApplicationStartUrl(
    dynamicAppBaseUrl, DynamicProduct.paypal, { search },
  );

  redirect(url);

  return <></>;
};

PayPalApplicationRedirectPage.displayName = PageComponentName.PayPalApplicationRedirect;

export default PayPalApplicationRedirectPage;
