import React from 'react';
import { Divider } from '@swift-paypal/pp-react';
import Header from '../header/Header';
import './hero.scss';

type HeroProps = {
  backgroundClass: string;
  header?: JSX.Element;
  content: JSX.Element;
  tickler?: JSX.Element;
};

const Hero: React.FC<HeroProps> = ({
  header = <Header hideNav={false} />,
  backgroundClass,
  content,
  tickler,
}) => (
  <div className={`hero ${backgroundClass}`}>
    {header}
    <Divider inverse className="no-margins" />
    {content}
    {(tickler ? (<div className="tickler">{tickler}</div>) : <></>)}
  </div>
);

export default React.memo(Hero);
