import React from 'react';
import {
  Container, Row, Col, Text, Button,
} from '@swift-paypal/pp-react';
import './download-a-loan-calculator.scss';

const DownloadALoanCalculator: React.FC = () => (
  <Container className="download-a-loan-calculator">
    <Row align="center" noGutters>
      <Col lg={8} sm={12}>
        <Text size="xl4" className="download-a-loan-calculator-title">Download a loan calculator</Text>
        <Text size="lg" className="download-a-loan-calculator-content">
          This simple tool can help you calculate your average monthly payroll costs which are the
          basis for your loan amount. You will be required to submit and certify your average
          monthly payroll costs as part of your application.
        </Text>
      </Col>
    </Row>
    <Row align="center" noGutters>
      <Col lg={8} sm={12} className="download-a-loan-calculator-button">
        <Button as="a" href="/ppp_calculator.xlsx" className="no-line-animation" secondary>
          Download Calculator
        </Button>
      </Col>
    </Row>
  </Container>
);

export default DownloadALoanCalculator;
