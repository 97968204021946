import React, { useContext } from 'react';
import {
  Container, Row, Col, Link, Text, Button,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../../util/appConfig';
import './whereCanIRequestForgiveness.scss';

const WhereCanIRequestForgiveness: React.FC = () => {
  const { customerPortalUrl, ppp21LoFoEnabled } = useContext(AppConfigContext);

  return (
    <div className="request-forgiveness">
      <Container className="request-forgiveness__container">
        <Row align="center">
          <Col md={10}>
            <Text size="xl4" as="h1" className="request-forgiveness__header">
              When can I request PPP loan forgiveness?
            </Text>
            {ppp21LoFoEnabled && (
              <Text size="xl">
                We are now accepting and processing loan forgiveness applications using the
                new SBA Form 3508S, 3508, and 3508EZ for loans funded in 2020 and 2021.
              </Text>
            )}
            {!ppp21LoFoEnabled && (
              <>
                <Text size="xl">
                  We are now accepting and processing loan forgiveness applications using the
                  new SBA Form 3508S, 3508, and 3508EZ for loans funded in 2020.
                </Text>
                <Text size="xl">
                  We currently expect to begin accepting loan forgiveness applications for loans
                  funded in 2021 in July.
                </Text>
              </>
            )}
            <Text size="xl">
              If your loan amount is $150,000 or less, once you log in all you need to do is answer
              a few questions and we&apos;ll create your loan forgiveness application for you.
            </Text>
            <Text size="xl">
              If your loan amount is greater than $150,000, once you log in we&apos;ll ask you a few
              questions to determine which loan forgiveness application is best for you.
            </Text>
            <Button
              as="a"
              className="no-line-animation request-forgiveness__button"
              href={customerPortalUrl}
            >
              Log in to Get Started
            </Button>
            <Text size="xl">
              Documentation will be required for loan forgiveness applications for loan amounts more
              than $150,000. For more information, you can review the forms below.
            </Text>
            <div className="request-forgiveness__sba-links">
              <Link
                className="no-line-animation"
                href="/sba_form_3508EZ.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                SBA Form 3508EZ &amp; Instructions
              </Link>
              <Link
                className="no-line-animation"
                href="/sba_form_3508.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                SBA Form 3508 &amp; Instructions
              </Link>
              <br />
              <Link
                className="no-line-animation"
                href="https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/paycheck-protection-program/ppp-loan-forgiveness#section-header-4"
                rel="noopener noreferrer"
                target="_blank"
              >
                Additional details and information can be found on the SBA&apos;s website.
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhereCanIRequestForgiveness;
