import React, { useContext } from 'react';
import { Button } from '@swift-paypal/pp-react';
import { useLocation } from 'react-router-dom';
import generateApplicationStartUrlByPartner from '../../../util/generateApplicationStartUrlByPartner';
import Partner from '../../../util/partner';
import { AppConfigContext } from '../../../util/appConfig';
import StorageKey from '../../../util/storageKey';
import './apply-now-button.scss';

export type ApplyNowButtonProps = {
  className?: string;
  mobile?: boolean;
};

const ApplyNowButton: React.FC<ApplyNowButtonProps> = (
  {
    className,
    mobile = false,
  },
) => {
  const location = useLocation();
  const partner = Partner.Sba;
  const { dynamicAppBaseUrl } = useContext(AppConfigContext);
  const leadOriginUrl = sessionStorage.getItem(StorageKey.LeadOriginUrl) as string;

  const classNames = [(mobile ? 'mobile-apply-now-button' : 'apply-now-button'), className || ''].join(' ');
  const url = generateApplicationStartUrlByPartner(dynamicAppBaseUrl, partner, leadOriginUrl, {
    search: location.search,
  });

  return (
    <Button
      as="a"
      href={url}
      className={classNames}
    >
      Apply Now
    </Button>
  );
};

export default ApplyNowButton;
