import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import FundingCodeValidation from '../../common/fundingCode/FundingCodeValidation';
import './direct-mail-content.scss';

const DirectMailContent: HeroContentComponent = () => (
  <Container as="section" className="hero-direct-mail-content">
    <Row>
      <Col>
        <Text size="xl4" as="h1">
          Build the loan that&apos;s right
          <br />
          for your business
        </Text>
        <Text size="xl2">Single fixed fee pricing and no origination fee.*</Text>
      </Col>
    </Row>
    <Row>
      <Col>
        <FundingCodeValidation />
      </Col>
    </Row>
    <Row className="disclosure-content">
      <Col>
        <Text size="sm" className="disclosure">
          *There is no fee deducted upfront from the loan proceeds.  Instead, the single fixed fee
          (Total Loan Fee) is paid over the life of the loan.  In addition to the Total Loan Fee,
          the only other cost is a $20 Returned Item Fee that is only assessed if a payment is
          returned.
        </Text>
      </Col>
    </Row>
  </Container>
);

DirectMailContent.backgroundClass = 'hero-direct-mail-background';

export default DirectMailContent;
