import Partner from './partner';
import DynamicProduct from './dynamicProduct';

export default function mapPartnerToProduct(partner: Partner) {
  switch (partner) {
    case Partner.PayPal:
      return DynamicProduct.paypal;
    case Partner.Sba:
      return DynamicProduct.sba;
    default:
      return DynamicProduct.app;
  }
}
