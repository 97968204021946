import React, { useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import usePartner from '../util/usePartner';
import Partner from '../util/partner';

export const withValidPartnerRedirect = (
  WrappedComponent: React.ComponentType,
  path: string,
) => (props: {}) => {
  const partner = usePartner();
  const [isValidPartner, setIsValidPartner] = useState(true);

  useMemo(() => {
    setIsValidPartner(Object.values(Partner).includes(partner));
  }, [partner]);

  return isValidPartner // eslint-disable-next-line react/jsx-props-no-spreading
    ? <WrappedComponent {...props} />
    : <Navigate to={path.replace(':partner', 'lb')} />;
};

export default withValidPartnerRedirect;
