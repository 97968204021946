import React from 'react';
import {
  Col,
  Container,
  Row,
  Text,
  Button,
} from '@swift-paypal/pp-react';
import './FundingUnavailable.scss';

const FundingUnavailable: React.FC = () => {
  const url = '/ppp-loan-info';
  return (
    <Container className="funding-available">
      <Row align="center">
        <Col className="content-block">
          <Text as="div" size="xl2">
            On May 4, 2021, the SBA announced that PPP loan funding had been depleted.
          </Text>
          <Text as="div" size="xl2">
            As a result, PayPal ceased accepting new PPP loan applications.
          </Text>
          <Text as="p" size="md">
            If you received your PPP loan through PayPal, we are now accepting
            and processing loan forgiveness applications for loans funded in 2020.
            We currently expect to begin accepting loan forgiveness applications for
            loans funded in 2021 in July.
          </Text>
          <Button as="a" href={url} className="funding-unavailable-button no-line-animation">
            Learn More
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FundingUnavailable;
