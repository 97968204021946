import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import Partner from '../../../util/partner';
import { AppConfigContext, AppConfig } from '../../../util/appConfig';

type CustomerSupportPhoneProps = {
  as?:
    | keyof JSX.IntrinsicElements
    | React.JSXElementConstructor<{className?: string}>;
  inverse?: boolean;
  className?: string;
};

export function getFriendlyNumber(appConfig: AppConfig, partner: Partner) {
  switch (partner) {
    case Partner.PayPal:
      return appConfig.customerSupportPhone.paypal;
    case Partner.LoanBuilder:
    default:
      return appConfig.customerSupportPhone.loanbuilder;
  }
}

export function getFunctionalNumber(friendlyNumber: string) {
  return friendlyNumber.replace(/[^0-9]/g, '');
}

const CustomerSupportPhone: React.FC<CustomerSupportPhoneProps> = ({
  as = 'a',
  ...rest
}) => {
  let { partner } = useParams() as { partner: Partner };
  if (!partner) {
    partner = Partner.LoanBuilder;
  }
  const appConfig = useContext(AppConfigContext);
  const friendly = getFriendlyNumber(appConfig, partner);
  const functional = getFunctionalNumber(friendly);

  return React.createElement(
    as,
    {
      ...rest,
      href: `tel:+${functional}`,
    } as (React.InputHTMLAttributes<HTMLInputElement>
      & React.ClassAttributes<HTMLInputElement>) | null | undefined,
    friendly,
  );
};

export default CustomerSupportPhone;
