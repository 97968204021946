import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';
import {
  BankDocuments, Doc1099, EmployeeBenefits, OtherDocuments,
} from '.';
import './getting-ready-to-apply.scss';

const GettingReadyToApply: React.FC = () => (
  <div className="getting-ready-to-apply">
    <Container>
      <Row align="center" noGutters>
        <Col lg={8} sm={10} className="title-and-text">
          <Text size="xl4">Getting ready to apply</Text>
          <Text size="lg" medium>
            To prepare to submit your application, we recommend that you gather full year 2019 and
            year to date 2020 information in the following categories as applicable.
          </Text>
        </Col>
      </Row>
      <Row align="start" noGutters>
        <Col lg={6} sm={12}>
          <div className="needed-documentation">
            <img
              src={Doc1099}
              alt=""
              className="image"
            />
            <Text size="xl">
              Payroll documentation
            </Text>
          </div>
          <div className="needed-documentation">
            <img
              src={EmployeeBenefits}
              alt=""
              className="image"
            />
            <Text size="xl">
              Employee benefit information
            </Text>
          </div>
        </Col>
        <Col lg={6} sm={12}>
          <div className="needed-documentation">
            <img
              src={OtherDocuments}
              alt=""
              className="image"
            />
            <Text size="xl">
              Payroll state & local tax filings
            </Text>
          </div>
          <div className="needed-documentation">
            <img
              src={BankDocuments}
              alt=""
              className="image"
            />
            <Text size="xl">
              Other documents such as bank statements
            </Text>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default GettingReadyToApply;
