import first from 'lodash/first';
import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Interweave from 'interweave';
import { Container, Col, Row } from '@swift-paypal/pp-react';
import PageComponentName from '../pageComponentName';
import { AppConfig, AppConfigContext } from '../../util/appConfig';
import routes from '../../routes/routes';
import RouteName from '../../routes/routeName';
import resolve from '../../util/resolve';
import Partner from '../../util/partner';
import './terms-and-conditions.scss';
import usePartner from '../../util/usePartner';

type TermsAndConditions = {
  brand: string;
  id: string;
  isActive: boolean;
  lastChangedDate: string;
  onlineFormattedCopy: string;
};

async function getTermsAndConditions(appConfig: AppConfig, partner: Partner) {
  let partnerString: string = partner;
  if (partner === Partner.LoanBuilder) {
    partnerString = 'LoanBuilder';
  }

  const response = await axios.get(`${appConfig.apiUrl}/terms-and-conditions/${partnerString}`);
  return response.data as TermsAndConditions[];
}

const TermsAndConditionsPage: React.FC = () => {
  const appConfig = useContext(AppConfigContext);
  const navigate = useNavigate();
  const partner = usePartner();
  const [markup, setMarkup] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const responseData = await getTermsAndConditions(appConfig, partner);
        const version = first(responseData);

        if (!version) {
          throw new Error('No result found');
        }

        setMarkup(version.onlineFormattedCopy);
      } catch (error) {
        navigate(resolve(routes[RouteName.Home].path, { partner }));
      }
    };

    fetchTerms();
  }, [appConfig, navigate, partner]);

  return (
    <div className="terms-and-conditions-page">
      <Container>
        <Row>
          <Col>
            {markup ? (<Interweave content={markup} />) : (<></>)}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

TermsAndConditionsPage.displayName = PageComponentName.TermsAndConditions;

export default TermsAndConditionsPage;
