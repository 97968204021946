import React from 'react';
import {
  Container, Row, Col, Text,
} from '@swift-paypal/pp-react';
import ApplyNowButton from '../applyNowButton/ApplyNowButton';
import PPPRowBullet from '../../pppShared/pppRowBullet/PPPRowBullet';
import { BusinessBlue, ClockBlue, PayrollBlue } from '.';
import './apply-for-ppp.scss';

const ApplyForPPP: React.FC = () => (
  <div className="apply-for-ppp">
    <Container>
      <Row align="center" noGutters>
        <Col lg={10} sm={12}>
          <Text size="xl4" className="apply-for-ppp-title">
            To apply for a Paycheck Protection Program loan through PayPal you will need to:
          </Text>
        </Col>
      </Row>
      <Row align="center" noGutters>
        <Col lg={4} sm={12}>
          <PPPRowBullet
            icon={PayrollBlue}
            iconAlt="A calendar highlighting a specific day with the American dollar currency sign."
            content={(
              <Text size="md">
                Agree to use funds for eligible payroll costs and eligible nonpayroll expenses such
                as rent, utilities, and mortgage interest.
              </Text>
            )}
          />
        </Col>
        <Col lg={4} sm={12}>
          <PPPRowBullet
            icon={ClockBlue}
            iconAlt="A clock showing 11:56."
            content={(
              <Text size="md">
                Apply while new PPP loan applications are still being accepted by the SBA. The SBA
                has confirmed that June 30, 2020 is the last date on which a PPP loan application
                can be approved.
              </Text>
            )}
          />
        </Col>
        <Col lg={4} sm={12}>
          <PPPRowBullet
            icon={BusinessBlue}
            iconAlt="A minimalist interpretation of a typical, small business store front."
            content={(
              <Text size="md">
                Certify among other things that your business has not already received a PPP loan
                from another provider.
              </Text>
            )}
          />
        </Col>
      </Row>
      <Row align="center" className="apply-now-cta" noGutters>
        <Col sm={12}>
          <ApplyNowButton />
          <ApplyNowButton mobile />
        </Col>
      </Row>
    </Container>
  </div>
);

export default ApplyForPPP;
