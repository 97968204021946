import React from 'react';
import {
  Col, Container, Row, Text,
} from '@swift-paypal/pp-react';
import PPPRowBullet from '../../pppShared/pppRowBullet/PPPRowBullet';
import SBALink from '../../pppLoan/sbaLink/SBALink';
import {
  NoPayments, OneHundredBlue, OnePercentBlue, TwoPointFiveTimesBlue,
} from './index';
import './loan-basics.scss';

const LoanBasics: React.FC = () => (
  <div className="ppp-2-loan-basics">
    <Container>
      <Row align="center" className="loan-basics-header-and-link">
        <Col md={10} sm={12}>
          <Text size="xl4" as="h2">
            Paycheck Protection Program (PPP) loan basics.
            <span>*</span>
          </Text>
          <SBALink />
        </Col>
      </Row>
      <Row align="center" className="loan-basics">
        <Col lg={3} sm={12}>
          <PPPRowBullet
            icon={OnePercentBlue}
            iconAlt=""
            content={(
              <Text size="md">
                The PPP loan has a 1% fixed interest rate. Loans that are approved by the SBA on or
                after June 5, 2020 have a five year term. There are no fees, and no personal
                guarantee or collateral required.
              </Text>
            )}
          />
        </Col>
        <Col lg={3} sm={12}>
          <PPPRowBullet
            icon={TwoPointFiveTimesBlue}
            iconAlt=""
            content={(
              <Text size="md">
                Borrow up to 2.5 times your average monthly payroll expenses.
              </Text>
            )}
          />
        </Col>
        <Col lg={3} sm={12}>
          <PPPRowBullet
            icon={NoPayments}
            iconAlt=""
            content={(
              <Text size="md">
                Payments will be deferred in accordance with SBA Guidelines.
              </Text>
            )}
          />
        </Col>
        <Col lg={3} sm={12}>
          <PPPRowBullet
            icon={OneHundredBlue}
            iconAlt=""
            content={(
              <Text size="md">
                Up to 100% of the total loan amount and accrued interest can be forgiven if used
                for eligible, approved expenses during SBA specified applicable time periods.
              </Text>
            )}
          />
        </Col>
      </Row>
      <Row align="center" className="loan-basics-disclaimers">
        <Col>
          <Text size="sm" className="disclaimer">
            *Terms and conditions apply. Loan subject to Lender Approval and SBA eligibility and
            approval criteria under the Paycheck Protection Program.
          </Text>
        </Col>
      </Row>
    </Container>
  </div>
);

export default LoanBasics;
