import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button, Dialog, Col, Row,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../../util/appConfig';
import generateApplicationStartUrlByPartner from '../../../util/generateApplicationStartUrlByPartner';
import StorageKey from '../../../util/storageKey';
import usePartner from '../../../util/usePartner';
import './invalid-funding-code-modal.scss';

type InvalidFundingCodeModalProps = {
  show?: boolean;
  onClose: () => void;
};

const InvalidFundingCodeModal: React.FC<InvalidFundingCodeModalProps> = (props) => {
  const {
    show,
    onClose,
  } = props;

  const partner = usePartner();
  const { search } = useLocation();
  const { dynamicAppBaseUrl } = useContext(AppConfigContext);
  const leadOriginUrl = sessionStorage.getItem(StorageKey.LeadOriginUrl) as string;
  const url = generateApplicationStartUrlByPartner(dynamicAppBaseUrl, partner, leadOriginUrl, {
    query: { directMail: 1 },
    search,
  });

  return (
    <Dialog
      center
      className="invalid-funding-code"
      closeOnBackgroundClick
      id="invalid-funding-code"
      isOpen={show}
      onClose={onClose}
      title="You&apos;ve entered an invalid code."
      footerAlign="center"
      footerContents={(
        <Row align="center">
          <Col sm={5} xs={12}>
            <Button
              as="a"
              className="no-code no-line-animation invalid-funding-code__button"
              href={url}
            >
              I DON&apos;T HAVE A CODE
            </Button>
          </Col>
          <Col sm={5} xs={12}>
            <Button
              type="button"
              className="different-code no-line-animation invalid-funding-code__button"
              onClick={onClose}
            >
              ENTER A DIFFERENT CODE
            </Button>
          </Col>
        </Row>
      )}
    />
  );
};

export default InvalidFundingCodeModal;
