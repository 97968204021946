import React, { useContext } from 'react';
import { Link as PPlink } from '@swift-paypal/pp-react';
import { Link } from 'react-router-dom';
import routes from '../../../../routes/routes';
import RouteName from '../../../../routes/routeName';
import { AppConfigContext } from '../../../../util/appConfig';
import resolve from '../../../../util/resolve';
import './secondaryNavigation.scss';
import usePartner from '../../../../util/usePartner';

declare global {
  interface Window {
    manageCookiePreferences: () => void;
  }
}

const SecondaryNavigation: React.FC = () => {
  const partner = usePartner();
  const { termsOfUseUrl, privacyPolicyUrl } = useContext(AppConfigContext);

  const navEntries = [
    { text: 'FAQs', route: resolve(routes[RouteName.FAQ].path, { partner }) },
    { text: 'Terms of Use', link: termsOfUseUrl },
    { text: 'Privacy Policy', link: privacyPolicyUrl },
    {
      text: 'Cookies',
      onClick: () => {
        if (window.manageCookiePreferences) {
          window.manageCookiePreferences();
        }
      },
    },
  ];

  const renderElement = (
    entry: {
      text: string,
      route?: string,
      link?: string,
      onClick?: () => void,
    },
  ) => {
    if (entry.route) {
      return (<Link to={entry.route} className="link-text">{entry.text}</Link>);
    }
    if (entry.link) {
      return (<a href={entry.link} className="link-text" target="_blank" rel="noopener noreferrer">{entry.text}</a>);
    }
    if (entry.onClick) {
      return (
        <PPlink
          as="a"
          className="link-text"
          onClick={entry.onClick}
        >
          {entry.text}
        </PPlink>
      );
    }
    return false;
  };

  return (
    <ul className="secondary-navigation">
      {navEntries.map((entry) => (
        <li key={entry.text}>
          {renderElement(entry)}
        </li>
      ))}
    </ul>
  );
};

export default SecondaryNavigation;
