import React from 'react';
import { Helmet } from 'react-helmet-async';
import StackLayout from '../layouts/stack/Stack';
import Hero from '../components/common/hero/Hero';
import Footer from '../components/common/footer/Footer';
import DirectMailContent from '../components/dm/directMailContent/DirectMailContent';
import WhyALoanBuilderLoan from '../components/common/content/whyALoanBuilderLoan/WhyALoanBuilderLoan';
import PageComponentName from './pageComponentName';
import Faqs from '../components/common/content/faqs/Faqs';
import SpeakWithOurBusinessFundingExpertsToday from '../components/common/content/speakWithOurBusinessFundingExpertsToday/SpeakWithOurBusinessFundingExpertsToday';
import ItTakesJustMinutesToCheckEligibility from '../components/common/content/itTakesJustMinutesToCheckEligibility/ItTakesJustMinutesToCheckEligibility';
import CallUsToday from '../components/common/content/callUsToday/CallUsToday';

const DirectMailPage: React.FC = () => (
  <div className="dm-page">
    <Helmet>
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout
      elements={[
        <Hero
          key="Hero"
          content={<DirectMailContent />}
          backgroundClass={DirectMailContent.backgroundClass}
        />,
        <WhyALoanBuilderLoan key="WhyALoanBuilderLoan" />,
        <Faqs key="Faqs" truncateCount={6} />,
        <SpeakWithOurBusinessFundingExpertsToday key="SpeakWithOurBusinessFundingExpertsToday" />,
        <ItTakesJustMinutesToCheckEligibility
          key="ItTakesJustMinutesToCheckEligibility"
          inverted
          blue
        />,
        <Footer key="Footer" />,
        <CallUsToday key="CallUsToday" />,
      ]}
    />
  </div>
);

DirectMailPage.displayName = PageComponentName.DirectMail;

export default DirectMailPage;
