import React from 'react';
import PageComponentName from '../pageComponentName';
import LeadFormRedirect from './LeadFormRedirect';
import LeadForm from './leadForm';

const BraintreeLeadsRedirectPage: React.FC = () => (
  <LeadFormRedirect
    leadForm={LeadForm.braintree}
  />
);

BraintreeLeadsRedirectPage.displayName = PageComponentName.BraintreeLeadsRedirect;

export default BraintreeLeadsRedirectPage;
