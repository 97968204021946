import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

export const withTracker = (
  WrappedComponent: React.ComponentType,
) => {
  const trackPageView = (path: string) => {
    TagManager.dataLayer({ dataLayer: { event: 'virtualPageView', virtualUrl: path } });
  };

  return (props: {}) => {
    const location = useLocation();
    const { pathname } = location;
    useEffect(() => {
      trackPageView(pathname);
    }, [pathname]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
  };
};

export default withTracker;
