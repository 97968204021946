import React from 'react';
import { Container, Row, Col } from '@swift-paypal/pp-react';
import './ppp-loan-info-bullet.scss';

type PPPLoanInfoBulletProps = {
  content: string | JSX.Element;
  icon: string;
  iconAlt?: string;
  blue?: boolean;
  classNames?: string;
};

const PPPLoanInfoBullet: React.FC<PPPLoanInfoBulletProps> = ({
  content, icon, iconAlt = '', blue, classNames,
}) => (
  <Container className={`ppp-loan-info-bullet ${classNames}`}>
    <Row align="start" className={classNames}>
      <Col sm={12} className="icon">
        <img src={icon} alt={iconAlt} />
      </Col>
      <Col sm={12} className={blue ? 'white-text' : 'grey-text'}>
        {content}
      </Col>
    </Row>
  </Container>
);

export default PPPLoanInfoBullet;
