import React from 'react';
import {
  Container, Col, Row, Text,
} from '@swift-paypal/pp-react';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import './hero-content.scss';

export type HeroContentProps = {
  title?: string;
  subtitle?: string;
  content?: string;
};

const HeroContent: HeroContentComponent<HeroContentProps> = ({
  title, subtitle, content, children,
}) => (
  <Container as="section" className="hero-ppp-loan-content">
    <Row align="start" noGutters>
      <Col sm={12} md={8}>
        <Text size="xl4" as="h1" className="hero-title">
          {title}
        </Text>
        {subtitle && (
          <Text size="xl4" as="h2" className="hero-sub-title">
            {subtitle}
          </Text>
        )}
        {content && (
          <>
            <br />
            <Text size="xl2" as="h2" className="hero-sub-title">
              {content}
            </Text>
          </>
        )}
        {children}
      </Col>
    </Row>
  </Container>
);

HeroContent.backgroundClass = 'hero-ppp-loan-background';

export default HeroContent;
