import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import FullStory from 'react-fullstory';
import PageComponentName from '../pageComponentName';
import StackLayout from '../../layouts/stack/Stack';
import Hero from '../../components/common/hero/Hero';
import ItTakesJustMinutesToCheckEligibility from '../../components/common/content/itTakesJustMinutesToCheckEligibility/ItTakesJustMinutesToCheckEligibility';
import MobileSubHero from '../../components/payPalBusinessLoan/content/mobileSubHero/MobileSubHero';
import PpblFooter from '../../components/payPalBusinessLoan/footer/PpblFooter';
import PpblHeroContent from '../../components/payPalBusinessLoan/heroContent/HeroContent';
import PpblHeader from '../../components/payPalBusinessLoan/header/Header';
import PpblFrequentlyAskedQuestions from '../../components/payPalBusinessLoan/content/frequentlyAskedQuestions/PpblFrequentlyAskedQuestions';
import SmallBusinessFunding from '../../components/payPalBusinessLoan/content/smallBusinessFunding/SmallBusinessFunding';
import SpeakWithOurBusinessFundingExperts from '../../components/payPalBusinessLoan/content/speakWithOurBusinessFundingExperts/SpeakWithOurBusinessFundingExperts';
import { AppConfigContext } from '../../util/appConfig';
import './paypal-business-loan.scss';

const PayPalBusinessLoanPage: React.FC = () => {
  const { fullstoryOrg } = useContext(AppConfigContext);

  return (
    <div className="ppbl-page">
      <Helmet>
        <title>Get a Small Business Loan Online - PayPal</title>
      </Helmet>
      <FullStory org={fullstoryOrg} />
      <StackLayout
        elements={[
          <Hero
            key="Hero"
            header={<PpblHeader />}
            content={<PpblHeroContent />}
            backgroundClass={PpblHeroContent.backgroundClass}
          />,
          <MobileSubHero key="MobileSubHero" />,
          <SmallBusinessFunding key="SmallBusinessFunding" />,
          <PpblFrequentlyAskedQuestions key="PpblFrequentlyAskedQuestions" />,
          <SpeakWithOurBusinessFundingExperts key="SpeakWithOurBusinessFundingExperts" />,
          <ItTakesJustMinutesToCheckEligibility
            key="ItTakesJustMinutesToCheckEligibility"
            ppblRedirect
            oneDisclaimer
          />,
          <PpblFooter key="PpblFooter" />,
        ]}
      />
    </div>
  );
};

PayPalBusinessLoanPage.displayName = PageComponentName.PayPalBusinessLoan;

export default PayPalBusinessLoanPage;
