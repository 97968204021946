import React from 'react';
import {
  Container, Row, Col,
} from '@swift-paypal/pp-react';
import './ppp-row-bullet.scss';

type PPPRowBulletProps = {
  content: string | JSX.Element;
  icon: string;
  iconAlt?: string;
  blue?: boolean;
  classNames?: string;
};

const PPPRowBullet: React.FC<PPPRowBulletProps> = ({
  content, icon, iconAlt, blue, classNames,
}) => (
  <Container className={`ppp-row-bullet ${classNames}`}>
    <Row className={classNames}>
      <Col lg={12} xs={1} className="icon">
        <img src={icon} alt={iconAlt} />
      </Col>
      <Col lg={12} xs={9} className={blue ? 'white-text' : 'grey-text'}>
        {content}
      </Col>
    </Row>
  </Container>
);

export default PPPRowBullet;
