import { useParams } from 'react-router-dom';
import Partner from './partner';

export default function usePartner() {
  let { partner } = useParams() as { partner?: Partner };
  if (!partner) {
    partner = Partner.LoanBuilder;
  }
  return partner;
}
