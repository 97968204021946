import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Col,
  Row,
  Button,
} from '@swift-paypal/pp-react';
import { AppConfigContext } from '../../../util/appConfig';
import MobileNavigation from './mobileNavigation/MobileNavigation';
import Navigation from './navigation/Navigation';
import resolve from '../../../util/resolve';
import routes from '../../../routes/routes';
import RouteName from '../../../routes/routeName';
import './header.scss';
import loanbuilderPayPalImage from '../../../shared/images/loanbuilder_paypal--vertical-color-knockout.svg';
import usePartner from '../../../util/usePartner';

type HeaderProps = {
  hideNav?: boolean;
};

const Header: React.FC<HeaderProps> = ({ hideNav }) => {
  const { customerPortalUrl } = useContext(AppConfigContext);
  const partner = usePartner();
  return (
    <section role="banner" className="header">
      <MobileNavigation hideNav={hideNav} />
      <Container>
        <Row>
          <Col xs={4}>
            <RouterLink to={resolve(routes[RouteName.Home].path, { partner })} className="no-line-animation">
              <img className="home-logo" src={loanbuilderPayPalImage} alt="LoanBuilder A PayPal Service Logo Knockout" />
            </RouterLink>
          </Col>
          {!hideNav && (
            <Navigation />
          )}
          {!hideNav && (
            <Col>
              <ul className="log-in right">
                <li>Already have a loan?</li>
                <li>
                  <Button
                    as="a"
                    className="underline-when-hover"
                    href={`${customerPortalUrl}login`}
                  >
                    Log in
                  </Button>
                </li>
              </ul>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Header;
