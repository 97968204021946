import React from 'react';
import {
  Col, Container, Row, Text,
} from '@swift-paypal/pp-react';
import RowBullet from '../../rowBullet/RowBullet';
import dollarSignImage from '../../../../shared/images/dollar-sign-ai.svg';
import clockImage from '../../../../shared/images/clock--gradient.svg';
import receiptImage from './receipt--gradient.svg';
import slidersImage from './sliders--gradient.svg';
import './whyALoanBuilderLoan.scss';

const WhyALoanBuilderLoanComponent: React.FC = () => (
  <div className="content-block why-a-loanbuilder-loan">
    <Container>
      <Row>
        <Col>
          <Text size="xl4" as="h2">Why a LoanBuilder Loan?</Text>
          <Text size="md">
            LoanBuilder may simplify and help take the mystery out of applying for a business loan.
          </Text>
          <div className="row-of-bullets">
            <RowBullet
              icon={slidersImage}
              title="Build your perfect loan"
              content="The LoanBuilder Configurator lets you see your estimated options upfront."
            />
            <RowBullet
              icon={dollarSignImage}
              title="Tailored terms"
              content={(
                <>
                  Choose the loan amount and
                  <br />
                  term that fit your
                  <br />
                  business needs.
                </>
              )}
            />
            <RowBullet
              icon={receiptImage}
              title="Clear competitive pricing"
              content={(
                <>
                  There is no origination fee and
                  <br />
                  no early repayment fees, just
                  <br />
                  one single fixed fee.
                  <sup>1</sup>
                </>
              )}
            />
            <RowBullet
              icon={clockImage}
              title="Fast access to cash"
              content={(
                <>
                  Check eligibility
                  <sup>2</sup>
                  {' '}
                  in minutes.
                  <br />
                  Complete the application,
                  <br />
                  and if approved, funds are
                  <br />
                  transferred as fast as the
                  <br />
                  next business day.
                  <sup>3</sup>
                </>
              )}
            />
          </div>
          <div className="disclosure">
            <Text className="disclosure-item" size="sm">
              The lender for LoanBuilder Loan is WebBank, Member FDIC.
            </Text>
            <Text className="disclosure-item" size="sm">
              <sup className="disclosure-item__sup">1</sup>
              There is no fee deducted upfront from the loan proceeds. Instead, the single fixed
              fee (Total Loan Fee) is paid over the life of the loan. In addition to the Total
              Loan Fee, the only other cost is a $20 Returned Item Fee that is only assessed if a
              payment is returned.
            </Text>
            <Text className="disclosure-item" size="sm">
              <sup className="disclosure-item__sup">2</sup>
              This is an invitation to apply and not an offer or commitment to provide capital.
              Applicants must satisfy certain requirements to be eligible. Lender approval required
              - terms and conditions apply.
            </Text>
            <Text className="disclosure-item" size="sm">
              <sup className="disclosure-item__sup">3</sup>
              The lender transfers funds as fast as the next business day for applications approved
              by 5:00 p.m. Eastern Time on bank business days.
            </Text>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

const WhyALoanBuilderLoan = React.memo(WhyALoanBuilderLoanComponent);
export default WhyALoanBuilderLoan;
