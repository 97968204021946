import React from 'react';
import {
  Container, Col, Row, Text,
} from '@swift-paypal/pp-react';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import './ppp2-hero.scss';

const Ppp2Hero: HeroContentComponent = () => (
  <Container as="section" className="ppp2-hero">
    <Row>
      <Col sm={12}>
        <Text size="xl4" as="h1" className="ppp2-hero__title">
          Paycheck Protection Program Update:
        </Text>
        <Text size="xl" className="ppp2-hero__text-block">
          The Covid Relief Bill signed into law in December 2020 provided additional loan funding
          for the Paycheck Protection Program (PPP).
        </Text>
        <Text size="xl" className="ppp2-hero__text-block">
          PayPal is honored to have participated in the PPP and is proud to have helped
          both PayPal merchants and the small business community access critical,
          business sustaining funds.
        </Text>
        <Text size="md" className="ppp2-hero__text-block">
          *The lender for the Paycheck Protection
          <br />
          Program loan through PayPal is WebBank,
          <br />
          Member FDIC.
        </Text>
      </Col>
    </Row>
  </Container>
);

Ppp2Hero.backgroundClass = 'ppp2-hero-background';

export default Ppp2Hero;
