import React from 'react';
import {
  BodyText,
  CaptionText,
  Col,
  Container,
  HeadingText,
  Row,
} from '@swift-paypal/pp-react';
import CheckEligibilityButton from '../../common/checkEligibilityButton/CheckEligibilityButton';
import './ppbl-check-eligibility.scss';

const PpblCheckEligibility: React.FC = () => (
  <div className="ppbl-check-eligibility-faq">
    <Container>
      <Row align="center">
        <Col md={8}>
          <HeadingText as="h2" className="ppvx_text--xl4 title">
            It takes just minutes to check eligibility.
            <sup>1</sup>
          </HeadingText>
          <CheckEligibilityButton ppblRedirect />
          <BodyText className="sub-title" strong>
            Checking eligibility will not affect your credit score.
            <sup>2</sup>
          </BodyText>
        </Col>
      </Row>
      <Row align="center" className="disclosures">
        <Col md={10}>
          <CaptionText as="p">
            <sup>1</sup>
            This is an invitation to apply and not an offer or commitment to provide capital.
            Applicants must satisfy certain requirements to be eligible. Lender approval required
            &mdash; terms and conditions apply.
          </CaptionText>
          <CaptionText as="p">
            <sup>2</sup>
            Completing the questionnaire will assess your business&apos;s eligibility, allow you
            to review estimated offers if eligible, and will not impact your credit score. Should
            you decide to complete the loan application process, credit checks and other public
            records checks will be performed which may impact your credit score.
          </CaptionText>
        </Col>
      </Row>
    </Container>
  </div>
);

export default PpblCheckEligibility;
