enum PageComponentName {
  BraintreeLeadsRedirect = 'BraintreeLeadsRedirectPage',
  CustomerPortalRedirect = 'CustomerPortalRedirectPage',
  Digital = 'Digital',
  DirectMail = 'DirectMailPage',
  FAQ = 'FaqsPage',
  Home = 'HomePage',
  PartnerRedirect = 'PartnerRedirectPage',
  PayPalApplicationRedirect = 'PayPalApplicationRedirectPage',
  PayPalBusinessLoan = 'PayPalBusinessLoan',
  PayPalBusinessLoanDm = 'PayPalBusinessLoanDm',
  PayPalBusinessLoanFaq = 'PayPalBusinessLoanFaq',
  PayPalBusinessLoanMoreInfo = 'PayPalBusinessLoanMoreInfo',
  PayPalLeadsRedirect = 'PayPalLeadsRedirectPage',
  PPP2 = 'PaycheckProtectionProgramLoanPage2',
  PPPLoan = 'PaycheckProtectionProgramLoanPage',
  PPPLoanApplication= 'PaycheckProtectionProgramLoanApplicationPage',
  PPPLoanInfo= 'PaycheckProtectionProgramLoanInfoPage',
  PPPLoanStart= 'PaycheckProtectionProgramLoanStartPage',
  PPPLoanSupport= 'PaycheckProtectionProgramLoanSupportPage',
  PPPLoanUpdate = 'PPPLoanUpdatePage',
  PPPLoanRedirect = 'PPPLoanRedirect',
  SitelinkFaq= 'SitelinkFaqPage',
  SitelinkMoreInfo= 'SitelinkMoreInfoPage',
  TermsAndConditions = 'TermsAndConditionsPage',
}

export default PageComponentName;
