import React from 'react';
import {
  Col, Container, Row, Text,
} from '@swift-paypal/pp-react';
import CheckEligibilityButton from '../../common/checkEligibilityButton/CheckEligibilityButton';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import './home-hero-content.scss';

const HomeHeroContent: HeroContentComponent = () => (
  <Container as="section" className="hero-home-content">
    <Row>
      <Col lg={7}>
        <Text size="xl4" as="h1">
          Build the loan that&apos;s right
          <br />
          for your business.
        </Text>
        <Text size="xl2">Single fixed fee pricing and no origination fee.*</Text>
        <CheckEligibilityButton className="ppvx_text--lg" />
        <Text size="sm" className="disclosure">
          *There is no fee deducted upfront from the loan proceeds.  Instead, the single fixed fee
          (Total Loan Fee) is paid over the life of the loan.  In addition to the Total Loan Fee,
          the only other cost is a $20 Returned Item Fee that is only assessed if a payment is
          returned.
        </Text>
      </Col>
    </Row>
  </Container>
);

HomeHeroContent.backgroundClass = 'hero-home-background';

export default HomeHeroContent;
