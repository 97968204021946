import React from 'react';
import {
  Col, Container, Link, Row, Text,
} from '@swift-paypal/pp-react';
import PPPRowBullet from '../../pppShared/pppRowBullet/PPPRowBullet';
import OnePercent from './one-percent.svg';
import DeferredPayment from './deferred-payment.svg';
import OneHundredPercent from './one-hundred-percent.svg';
import './ppp-loan-basics.scss';

const PppLoanBasics: React.FC = () => (
  <div className="ppp-loan-basics">
    <Container className="ppp-loan-basics">
      <Row align="center">
        <Col>
          <Text size="xl4" as="h1" className="ppp-loan-basics__header">
            Paycheck Protection Program (PPP) loan basics.
          </Text>
          <div className="ppp-loan-basics__sba-link">
            <Link
              className="no-line-animation"
              href="https://www.sba.gov/funding-programs/loans/covid-19-relief-options/paycheck-protection-program"
              rel="noreferrer noopener"
              target="_blank"
            >
              Click here to access all the details directly from the SBA.
            </Link>
          </div>
        </Col>
      </Row>
      <Row align="center">
        <Col lg={3} md={10}>
          <PPPRowBullet
            icon={OnePercent}
            iconAlt=" "
            content={(
              <Text size="md">
                The PPP loan has a five year term with a 1% fixed interest rate. There are no fees,
                and no personal guarantee or collateral required.
              </Text>
            )}
          />
        </Col>
        <Col lg={3} md={10}>
          <PPPRowBullet
            icon={DeferredPayment}
            iconAlt=" "
            content={(
              <Text size="md">
                Payments will be deferred in accordance with SBA guidelines.
              </Text>
            )}
          />
        </Col>
        <Col lg={3} md={10}>
          <PPPRowBullet
            icon={OneHundredPercent}
            iconAlt=" "
            content={(
              <Text size="md">
                Up to 100% of the total loan amount and accrued interest can be forgiven if used for
                eligible, approved expenses.*
              </Text>
            )}
          />
        </Col>
      </Row>
      <Row align="center">
        <Col lg={10}>
          <Text size="sm" className="ppp-loan-basics__terms-and-conditions">
            *PPP loan forgiveness is subject to SBA approval.
            The amount of loan forgiveness will depend on your use of the PPP loan funds,
            among other SBA eligibility factors.
          </Text>
        </Col>
      </Row>
    </Container>
  </div>
);

export default PppLoanBasics;
