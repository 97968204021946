import React from 'react';
import {
  BodyText,
  Col,
  Container,
  HeadingText,
  Link,
  Row,
} from '@swift-paypal/pp-react';
import './speakWithOurBusinessFundingExperts.scss';

const SpeakWithOurBusinessFundingExperts: React.FC = () => (
  <div className="content-block inverted speak-with-our-business-funding-experts">
    <Container>
      <Row>
        <Col md={9}>
          <HeadingText as="h2" className="ppvx_text--xl4">
            Speak with our team today.
          </HeadingText>
          <BodyText as="p" className="ppvx_text--xl">
            Our U.S.-based team is available between 9 a.m. and 8 p.m. ET Monday - Friday and
            between 11 a.m. to 3 p.m. on Saturday.
          </BodyText>
          <BodyText as="p" className="ppvx_text--xl2">
            Call us at
            {' '}
            <Link className="no-line-animation ppvx_link" href="tel:1-800-941-5614">
              1-800-941-5614
            </Link>
            .
          </BodyText>
        </Col>
      </Row>
    </Container>
  </div>
);

export default SpeakWithOurBusinessFundingExperts;
