import React from 'react';
import {
  Container, Row, Col, Link,
} from '@swift-paypal/pp-react';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import CheckEligibilityButton from '../../checkEligibilityButton/CheckEligibilityButton';
import CustomerSupportPhone from '../../customerSupportPhone/CustomerSupportPhone';
import './callUsToday.scss';

const CallUsToday: React.FC = () => {
  const position = useWindowScrollPosition({ throttle: 100 });
  const show = position.y >= 200;

  return (
    <div className={`call-us-today ${show ? 'visible' : ''}`}>
      <Container>
        <Row>
          <Col className="inner-container">
            <div className="has-divider">
              <span className="ppvx_text--xl ppvx_text--medium">Call Us Today:</span>
              &nbsp;
              <CustomerSupportPhone as={Link} inverse className="ppvx_text--xl phone-link" />
            </div>
            <div className="has-button">
              <CheckEligibilityButton secondary inverse />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CallUsToday;
