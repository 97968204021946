import Partner from './partner';
import mapPartnerToProduct from './mapPartnerToProduct';
import generateApplicationStartUrl, { GenerateStartUrlOptions } from './generateApplicationStartUrl';

export default function generateApplicationStartUrlByPartner(
  dynamicAppBaseUrl: string,
  partner: Partner,
  leadOriginUrl: string,
  options: GenerateStartUrlOptions = { query: {} },
) {
  const product = mapPartnerToProduct(partner);

  const generateStartUrlOptions = {
    query: {
      ...options.query,
      leadOriginUrl,
    },
    search: options.search,
  };

  return generateApplicationStartUrl(dynamicAppBaseUrl, product, generateStartUrlOptions);
}
