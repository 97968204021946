import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Container, Col, Row, Text,
} from '@swift-paypal/pp-react';
import SecondaryNavigation from './secondaryNavigation/SecondaryNavigation';
import CustomerSupportPhone from '../customerSupportPhone/CustomerSupportPhone';
import routes from '../../../routes/routes';
import RouteName from '../../../routes/routeName';
import resolve from '../../../util/resolve';
import logoImage from '../../../shared/images/loanbuilder_paypal--horizontal.svg';
import { AppConfigContext } from '../../../util/appConfig';
import usePartner from '../../../util/usePartner';
import './footer.scss';

type FooterProps = {
  hideDisclosure?: boolean;
  hideNav?: boolean;
};

const Footer: React.FC<FooterProps> = ({ hideDisclosure, hideNav }) => {
  const partner = usePartner();
  const { version } = useContext(AppConfigContext);
  const currentYear = moment().year();

  return (
    <div className="footer">
      <Container className="footer-container">
        {
          !hideDisclosure && (
            <Row>
              <Col>
                <Text size="sm" className="padding-bottom">The lender for LoanBuilder Loan is WebBank, Member FDIC.</Text>
              </Col>
            </Row>
          )
        }
        <Row>
          <Col md={6}>
            <RouterLink to={resolve(routes[RouteName.Home].path, { partner })} className="no-line-animation">
              <img
                src={logoImage}
                alt="LoanBuilder A PayPal Service Logo"
                className="footer__logo"
              />
            </RouterLink>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            { !hideNav && <SecondaryNavigation /> }
          </Col>
          <Col sm={6} className="right-column">
            <Text>
              Call us at
              {' '}
              <CustomerSupportPhone />
            </Text>
            <Text>
              2211 North First Street
              <br />
              San Jose, California 95131
            </Text>
            <>
              <Text>LoanBuilder is a service of PayPal, Inc.</Text>
              <Text>
                Copyright ©
                {` ${currentYear} `}
                PayPal Inc. All rights reserved.
              </Text>
            </>
            <Text className="version">{version}</Text>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
