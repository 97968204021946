import React from 'react';
import {
  Container, Row, Col, HeadingText,
} from '@swift-paypal/pp-react';
import CheckEligibilityButton from '../../common/checkEligibilityButton/CheckEligibilityButton';
import HeroContentComponent from '../../common/hero/heroContentComponent';
import './ppbl-faq-hero-content.scss';

const HeroContent: HeroContentComponent = () => (
  <Container as="section" className="ppbl-faq-hero">
    <Row>
      <Col sm={12} lg={8}>
        <HeadingText as="h1" className="ppvx_text--xl4">
          Questions about PayPal Business Loan?
        </HeadingText>
        <div className="sub-header-text">
          <HeadingText as="h2" className="ppvx_text--xl">
            See below for answers to frequently asked questions.
          </HeadingText>
          <CheckEligibilityButton className="ppbl-faq-hero__button" ppblRedirect />
        </div>
      </Col>
    </Row>
  </Container>
);

HeroContent.backgroundClass = 'hero-ppbl-background';

export default HeroContent;
