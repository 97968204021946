import LeadForm from './leadForm';
import DynamicProduct from '../../util/dynamicProduct';

export default function mapLeadFormToProduct(leadForm: LeadForm) {
  switch (leadForm) {
    case LeadForm.braintree:
      return DynamicProduct.braintreeLeadForm;
    default:
      return DynamicProduct.paypalLeadForm;
  }
}
