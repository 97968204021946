import React from 'react';
import { Helmet } from 'react-helmet-async';
import PpblHeader from '../../../components/payPalBusinessLoan/header/Header';
import PageComponentName from '../../pageComponentName';
import EligibleExpenses from '../../../components/pppLoanInfo/eligibleExpenses/EligibleExpenses';
import LoanProceedsMustBeUsed from '../../../components/pppLoanInfo/loanProceedsMustBeUsed/LoanProceedsMustBeUsed';
import Hero from '../../../components/common/hero/Hero';
import MobileHeroText from '../../../components/pppLoanInfo/mobileHeroText/MobileHeroText';
import PPPLoanInfoHero from '../../../components/pppLoanInfo/pppLoanInfoHero/PPPLoanInfoHero';
import SpeakWithOurLoanForgivenessSpecialists from '../../../components/pppLoanInfo/speakWithOurLoanForgivenessSpecialists/SpeakWithOurLoanForgivenessSpecialists';
import WebBankDisclaimer from '../../../components/pppShared/WebBankDisclaimer';
import WhereCanIRequestForgiveness from '../../../components/pppLoanInfo/whereCanIRequestForgiveness/WhereCanIRequestForgiveness';
import WhoCanUse3508S from '../../../components/pppLoanInfo/whoCanUse3508S/WhoCanUse3508S';
import StackLayout from '../../../layouts/stack/Stack';
import './ppp-loan-info.scss';

const PPPLoanInfoPage: React.FC = () => (
  <>
    <Helmet>
      <title>Build Your Own Business Loan | Small Business Loans | LoanBuilder</title>
    </Helmet>
    <StackLayout elements={[
      <Hero
        key="Hero"
        header={<PpblHeader hideSignUp />}
        content={(
          <PPPLoanInfoHero />
        )}
        backgroundClass={PPPLoanInfoHero.backgroundClass}
      />,
      <MobileHeroText key="MobileHeroText" />,
      <WhoCanUse3508S key="WhoCanUse3508S" />,
      <EligibleExpenses key="EligibleExpenses" />,
      <LoanProceedsMustBeUsed key="LoanProceedsMustBeUsed" />,
      <WhereCanIRequestForgiveness key="WhereCanIRequestForgiveness" />,
      <SpeakWithOurLoanForgivenessSpecialists key="SpeakWithOurLoanForgivenessSpecialists" />,
      <WebBankDisclaimer key="WebBankDisclaimer" />,
    ]}
    />
  </>
);

PPPLoanInfoPage.displayName = PageComponentName.PPPLoanInfo;

export default PPPLoanInfoPage;
