import React from 'react';
import { Navigate } from 'react-router-dom';
import routes from '../routes/routes';
import PageComponentName from './pageComponentName';

const PPPLoanRedirectPage: React.FC = () => (
  <Navigate
    to={routes['ppp-loan'].path}
  />
);

PPPLoanRedirectPage.displayName = PageComponentName.PPPLoanRedirect;

export default PPPLoanRedirectPage;
